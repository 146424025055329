import React, { useEffect, useState } from "react";
import ArticleTextFieldsUsedBikes from "../components/ArticleTextFieldsUsedBikes";
import Header from "../components/Header";

import "./UsedBikesForm.css";
import { TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { logout } from "../Redux/actions/userActions";
import { useDispatch } from "react-redux";
import API from "../utils/API";
import Modal from "../components/Modal-ConfirmAlert";
// import { useSelector, useDispatch } from "react-redux";
// import {
//   getArticle,
//   getManufacturer,
//   getCategory,
//   getSupplier,
//   getShop,
// } from "../Redux/actions/articleActions";

function UsedBikesForm() {
    const [article, setArticle] = useState("");
    const [articleData, setArticleData] = useState([]);
    const [name, setName] = useState("");
    const [comment, setComment] = useState("");
    const [internComment, setInternComment] = useState("");
    const [manufacturerId, setManufacturerId] = useState("");
    const [manufacturer, setManufacturer] = useState("");
    const [manufacturerData, setManufacturerData] = useState([]);
    const [supplierId, setSupplierId] = useState("");
    const [supplier, setSupplier] = useState("");
    const [supplierData, setSupplierData] = useState([]);
    const [mainCategoryId, setMainCategoryId] = useState("");
    const [category, setCategory] = useState("");
    const [categoryData, setCategoryData] = useState([]);
    const [shop, setShop] = useState("");
    const [shopData, setShopData] = useState([]);
    const { handleSubmit } = useForm();
    const [search, setSearch] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [title, setTitle] = useState("");
    const [userNameData, setUserNameData] = useState("");
    const dispatch = useDispatch();

    const closeModalHandler = () => setShowModal(false);

    async function fetchData(e) {
        e.preventDefault();
        setArticleData(article[0]);
        setSupplierId("");
        setManufacturerId("");
        setMainCategoryId("");

        if (article === "" || null) {
            setShowModal(true);
            setTitle("Sökfältet är tomt!");
        } else {
            await API.getArticle(article)
                .then((response) => {
                    if (response.status === 500 || response.status === 400) {
                        // throw new Error("status: ", response.status);
                        alert("Meddelandet har inte skickats iväg");
                    }
                    if (response.status === 200) {
                        // 200 svar
                        const data = response.data;
                        setArticleData([data]);
                        setSupplierId(response.data.SupplierId);
                        setManufacturerId(response.data.ManufacturerId);
                        setMainCategoryId(response.data.MainCategoryId);
                    } else if (response.status === 401) {
                        // 401 svar
                        dispatch(logout());
                    } else if (response.status === 404) {
                        // 404 svar
                        setArticleData(article[0]);
                    }
                })

                .catch((error) => {
                    // alert("något gick fel, försök igen");
                    setShowModal(true);
                    setTitle("något gick fel, försök igen");
                });
        }
    }

    useEffect(() => {

        const getSupplier = async () => {
            await API.getSupplier()
                .then((response) => {
                    setSupplierData(response.data);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        getSupplier();

        const getManufacturer = async () => {
            await API.getManufacturer()
                .then((response) => {
                    setManufacturerData(response.data);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        getManufacturer();

        const getCategory = async () => {
            await API.getCategory()
                .then((response) => {
                    setCategoryData(response.data);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        getCategory();

        const getShop = async () => {
            await API.getUserShop()
                .then((response) => {
                    response.data.sort((a, b) => a.Name.localeCompare(b.Name));
                    setShopData(response.data);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        getShop();

        const getUserName = async () => {
            await API.getUserName()
                .then((response) => {
                    setUserNameData(response.data);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        getUserName();

    }, []);

    const handleSelectedShop = (e) => {
        setShop(e.target.value);
    };

    const handleSelectedManufacturer = (e) => {
        setManufacturer(e.target.value);
    };

    const handleSelectedSupplier = (e) => {
        setSupplier(e.target.value);
    };

    const handleSelectedCategory = (e) => {
        setCategory(e.target.value);
    };

    // hides the form and shows the success message
    const changeSearchStatus = () => {
        setSearch(false);

        //setShowModal(true);
        //setTitle("Begagnatbegäran har skickats!");
    };

    const handleClear = () => {
        setArticle("");
    };

    const handleCountMargin = (thePurchasePrice, theSalePrice) => {

        let tempMargin = "";

        if(theSalePrice != 0 && thePurchasePrice != 0 && !isNaN(theSalePrice) && !isNaN(thePurchasePrice)) {
            tempMargin = ((theSalePrice - (thePurchasePrice * 1.25)) / theSalePrice);
            tempMargin = tempMargin * 100;
            tempMargin = new Number(tempMargin).toFixed(2);
        }

        if(!isNaN(tempMargin))
            return tempMargin;
    };

    return (
        <div className="usedBikesForm">
            <form onSubmit={handleSubmit}>
                <div className="usedBikesForm__inputContainer">
                    <Header title="Begagnatbegäran" />
                    <TextField
                        className="usedBikesForm__inputText"
                        label="Ange artikelnr / streckkod"
                        variant="outlined"
                        type="text"
                        name="comment"
                        value={article}
                        onChange={(e) => setArticle(e.target.value)}
                    />

                    <button
                        className="usedBikesForm__searchBtn"
                        type="submit"
                        onClick={(e) => {
                            setArticleData(fetchData(e));
                            setSearch(true);
                        }}
                    >
                        SÖK
                    </button>
                    <Modal
                        showModal={showModal}
                        closeModalHandler={closeModalHandler}
                        title={title}
                        buttonName="Stäng"
                    />
                </div>
            </form>

            {search && (
                <div className="usedBikesForm__formContainer">
                    {Object.keys(articleData).map((p) => (
                        <div key={articleData[0].ProductId}>
                            <div className="horizintalLine">
                                <hr />
                            </div>
                            <ArticleTextFieldsUsedBikes
                                ProductId={articleData[p].ProductId}
                                PartNo={article}
                                BarCode={articleData[p].BarCode}
                                ProductName={articleData[p].ProductName}
                                Comment={comment}
                                InternComment={internComment}
                                commentData={(e) => setComment(e.target.value)}
                                internCommentData={(e) => setInternComment(e.target.value)}
                                selectManufacturer={handleSelectedManufacturer}
                                ManufacturerId={manufacturerId}
                                manufacturerData={manufacturerData}
                                selectSupplier={handleSelectedSupplier}
                                SupplierId={supplierId}
                                supplierData={supplierData}
                                selectCategory={handleSelectedCategory}
                                MainCategoryId={mainCategoryId}
                                categoryData={categoryData}
                                PurchasePrice={articleData[p].PurchasePrice}
                                SalePrice={articleData[p].SalePrice}
                                Margin={handleCountMargin(articleData[p].PurchasePrice, articleData[p].SalePrice)}
                                selectShop={handleSelectedShop}
                                WarehouseId={shop}
                                Shop={shopData[0].Code}
                                shopData={shopData}
                                userNameData={userNameData}
                                name={name}
                                // nameInput={(e) => setName(e.target.value)}
                                changeSearchStatus={changeSearchStatus}
                                handleClear={handleClear}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default UsedBikesForm;
