import React from "react";
import "./style.css";
//import Banner from "../Banner";

//import { useDispatch } from "react-redux";
//import { logout } from "../../Redux/actions/userActions";

function Header({ title }) {
  return (
    <div className="header">
      <div className="header__section1">
        <h1 className="header__title">{title}</h1>
      </div>
      {/* <div className="header__bannerContainer">
        <Banner title="Aktuellt på Sportson" />
      </div> */}
    </div>
  );
}

export default Header;
