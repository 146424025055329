import {
  createStore,
  combineReducers,
  applyMiddleware,
} from "@reduxjs/toolkit";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk"; // Do async call

// import reducer
import { userReducer } from "./reducers/userReducer";
import { orderReducer } from "./reducers/orderReducer";
import { articleReducer } from "./reducers/articleReducer";
import {
  giftCardReducer,
  // updateGiftCardReducer,
} from "./reducers/giftCardReducer";
import {
  headsFormReducer,
  butikFormReducer,
  portalFormReducer,
  supportTypeReducer,
  supportSubTypeReducer,
} from "./reducers/itSupportReducer";

const reducer = combineReducers({
  // reducer
  user: userReducer,
  order: orderReducer,
  article: articleReducer,
  card: giftCardReducer,
  // updateCard: updateGiftCardReducer,
  headsFormReducer: headsFormReducer,
  butikFormReducer: butikFormReducer,
  portalFormReducer: portalFormReducer,
  supportTypeReducer: supportTypeReducer,
  supportSubTypeReducer: supportSubTypeReducer,
});

// Get info from local storage
const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getUser("userInfo"))
  : null;

// Get userInfo from local storage, in case the user refresh the page
const initialState = { user: { userInfoFromStorage } };

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
