import React, { useState } from "react";
import "./style.css";
//import cx from "classnames";
import Switched from "react-switch";

function Switch({ rounded = false }) {
  const [checked, setChecked] = useState(true);
  /* Remove unused code for now
  const sliderCX = cx("slider", {
    rounded: rounded,
  });
   */
  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };
  return (
    <div>
      {/* <label className="switch">
        <input type="checkbox" className="switch__input" checked />
        <span className={sliderCX} />
      </label> */}
      <label>
        <Switched
          offColor="#F2C9C9"
          onColor="#C6F2C4"
          offHandleColor="#ccc"
          onHandleColor="#ccc"
          onChange={handleChange}
          checked={checked}
          className="react-switch"
          uncheckedIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 15,
                color: "black",
                paddingRight: 5,
              }}
            >
              AV
            </div>
          }
          checkedIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 15,
                color: "black",
                paddingLeft: 2,
              }}
            >
              PÅ
            </div>
          }
        />
      </label>
    </div>
  );
}

export default Switch;
