import React, { useState } from "react";
import RadioButtons from "../RadioButtons";
import { TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import "./style.css";
import Modal from "../Modal-ConfirmAlert";

import API from "../../utils/API";

function ItButik({ selectedValue, option, supportSubTypeID }) {
  const [device, setDevice] = useState("");
  const [description, setDescription] = useState("");
  const [checked, isChecked] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const [files, setFiles] = useState([]);

  const closeModalHandler = () => setShowModal(false);

  const handleOnChange = (e) => {
    const fileList = e.target.files;
    let tempFiles = [];
    let index = 0; // Setting index to array index so it is easy to remove an element in the handleRemoveFile function

    for (let file of fileList) {
      tempFiles.push({ index: index, file: file, name: file.name});
      index++;
    }
    for (let file of files) {
      tempFiles.push({ index: index, file: file.file, name: file.name});
      index++;
    }
    
    setFiles(tempFiles);
  }

  const handleRemoveFile = (e) => {
    let tempList = files;
    if (tempList.length === 1){
      setFiles([]);
    } else {
      tempList.splice(e.target.id, 1);
      for (let i = parseInt(e.target.id); i < tempList.length; i++) {
        tempList[i].index = i;
      }
      setFiles([...tempList]);
    }
  }

  const { control, handleSubmit, errors, register /*, reset */ } = useForm({
    reValidateMode: "onChange",
    defaultValues: {
      device,
      description,
      files,
      checked,
    },
  });

  const onSubmit = (e) => {
    // e.preventDefault();

    const ticketbody = {
      device,
      description,
      files,
      checked,
    };

    API.postSupportTicket(ticketbody, supportSubTypeID)
      .then((ticketid) => {
        setShowModal(true);
        setTitle(`Ditt supportärende med nummer ${ticketid} har skapats!`);
        handleClear();
      })
      .catch((error) => {
        setShowModal(true);
        setTitle("Något gick fel, din förfrågan har inte skickats");
      });
    /*
    API.postFiles(files)
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
     */
  };

  const handleClear = () => {
    setDevice("");
    setDescription("");
    setFiles([]);
    isChecked("");
  };

  const handleChange = (e) => {
    setDescription(e.target.value);
  };

  const handleRadioBtn = (e) => {
    isChecked(e.target.value);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {" "}
        {errors.device && (
          <p className="errorMessage" style={{ color: "red" }}>
            {" "}
            Ange typ av enhet *
          </p>
        )}
        <select
          className={"itSupport__select"}
          name="device"
          error={errors.device}
          onChange={(e) => setDevice(e.target.value)}
          value={device}
          ref={register({ required: true })}
        >
          <option className="itSupport__optionText" value="">
            Vad för typ av enhet krånglar? *
          </option>
          <option>Skrivare</option>
          <option>Etikettskrivare</option>
          <option>Nätverk (router, switch, kablar, wifi)</option>
          <option>Dator</option>
          <option>Övrigt</option>
        </select>
        <TextField
          className="itButik__input"
          control={control}
          inputRef={register({ required: true })}
          label="Beskriv felet så utförligt som möjligt *"
          variant="outlined"
          name="description"
          error={errors.description}
          multiline
          rows={4}
          fullWidth
          onChange={handleChange}
          value={description}
        ></TextField>
        <div className="fileUpload">
          <TextField
              name="fileField"
              label="Ladda upp filer"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              fullWidth
          >
          </TextField>
          <input value="" title="" className="fileInput" type="file" placeholder="" name="file" onChange={handleOnChange} multiple />
          {files.length > 0 ? <ul className="filesUl">
            {files.map((file) => (
                <li>
                  {file.name} <button className="removeFileButton" type="button" onClick={handleRemoveFile} id={file.index}>X</button>
                </li>
            ))}
          </ul>: null}
        </div>
        <RadioButtons
          checked={handleRadioBtn}
          // ref={register({ required: true })}
        />
        <button
          className="modal__button"
          type="submit"
          closeModalHandler={closeModalHandler}
        >
          Skicka
        </button>
        <Modal
          showModal={showModal}
          closeModalHandler={closeModalHandler}
          title={title}
          buttonName="Stäng"
        />
      </form>
    </div>
  );
}

export default ItButik;
