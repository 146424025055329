// All It-support reducers

export const headsFormReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case "POST_HEADS_FORM":
      return { loading: true };
    case "POST_HEADS_FORM_FAIL":
      return { loading: false, error: payload };

    default:
      return state;
  }
};

export const butikFormReducer = (state = { butiksInfo: [] }, action) => {
  const { type, payload } = action;

  switch (type) {
    case "POST_BUTIK_FORM":
      return { loading: true };
    case "POST_BUTIK_FORM_FAIL":
      return { loading: false, error: payload };

    default:
      return state;
  }
};

export const portalFormReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case "POST_PORTAL_FORM":
      return { loading: true };
    case "POST_PORTAL_FORM_FAIL":
      return { loading: false, error: payload };

    default:
      return state;
  }
};

export const supportTypeReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case "POST_SUPPORT_TYPE":
      return { loading: true };
    case "POST_SUPPORT_TYPE_FAIL":
      return { loading: false, error: payload };

    default:
      return state;
  }
};

export const supportSubTypeReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case "POST_SUPPORT_SUB_TYPE":
      return { loading: true };
    case "POST_SUPPORT_SUB_TYPE_FAIL":
      return { loading: false, error: payload };

    default:
      return state;
  }
};
