import React from "react";
import "./style.css";
import { Dialog, DialogActions, DialogTitle, Slide } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createMuiTheme({
  shape: {
    borderRadius: 10,
  },
});

function Modal({ title, buttonName, showModal, closeModalHandler }) {
  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Dialog
          className="modal"
          open={showModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={closeModalHandler}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogActions className="modal__topHeader">
            {" "}
            <span onClick={closeModalHandler} className="modal__closeBtn">
              x
            </span>
          </DialogActions>
          <DialogTitle className="modal__header">{title}</DialogTitle>
          <button onClick={closeModalHandler} className="modal__button">
            {buttonName}{" "}
          </button>
        </Dialog>
      </MuiThemeProvider>
    </div>
  );
}

export default Modal;
