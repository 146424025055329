import React, { useState } from "react";
import "./style.css";
//import PrintComponent from "../PrintComponent";
import html2pdf from "html2pdf.js";
import ProductRow from "../ProductRow-Customer";
import ProductRowShopView from "../ProductRow-Shop";
import Comment from "../Comment";
import { useSelector } from "react-redux";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

function OrderForm() {
  const [toggleState, setToggleState] = useState(1);
  const [expandedCustomerComments, setExpandedCustomerComments] = useState(true);
  const [expandedInternalComments, setExpandedInternalComments] = useState(true);

  const { orderInfo } = useSelector((state) => state.order);

  /* Remove unused code for now
  const toggleTab = (index) => {
    setToggleState(index);
  };
   */

  const back = (e) => {
    e.preventDefault();

    document.getElementsByClassName("orderhantering__filterSearchBtn")[0].scrollIntoView({behavior: 'smooth'});
  }

  const print = (e) => {
    let order = document.getElementsByClassName("orderForm")[0];
    let buttonOne = document.getElementsByClassName("orderForm__button")[0];
    let buttonTwo = document.getElementsByClassName("orderForm__button")[1];
    let sumsContainer = document.getElementsByClassName("orderForm__sumsContainerDiv")[1];
    let opt = {
      margin:       [0, 0.2, 0.2, 0.2],
      filename:     'order.pdf',
      image:        { type: 'jpeg', quality: 0.98 },
      html2canvas:  { scale: 2, width: 1500, },
      jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
    };

    e.preventDefault();

    // Hide on print:
    buttonOne.classList.add("orderForm__hidden");
    buttonTwo.classList.add("orderForm__hidden");
    sumsContainer.classList.add("orderForm__hidden");

    html2pdf().from(order).set(opt).save().then(()=>{
      buttonOne.classList.remove("orderForm__hidden");
      buttonTwo.classList.remove("orderForm__hidden");
      sumsContainer.classList.remove("orderForm__hidden");
    });
  }

  const printGDPR = (e) => {
    let order = document.getElementsByClassName("orderForm")[0];
    let buttonOne = document.getElementsByClassName("orderForm__button")[0];
    let buttonGDPR = document.getElementsByClassName("orderForm__buttonGDPR")[0];
    let buttonTwo = document.getElementsByClassName("orderForm__button")[1];
    let sumsContainer = document.getElementsByClassName("orderForm__sumsContainerDiv")[1];
    let customerAddress = document.getElementsByClassName("orderForm__customerCardRowItem")[3];
    let customerSSN = document.getElementsByClassName("orderForm__customerCardRowItem")[5];
    let customerPostalCode = document.getElementsByClassName("orderForm__customerCardRowItem")[7];
    let customerEmail = document.getElementsByClassName("orderForm__customerCardRowItem")[9];
    let customerEmployer = document.getElementsByClassName("orderForm__customerCardRowItem")[11];
    let customerPhoneNumber = document.getElementsByClassName("orderForm__customerCardRowItem")[13];
    let deletedArticles = document.getElementsByClassName("productRow__itemIsDeleted");

    let opt = {
      margin:       [0, 0.2, 0.2, 0.2],
      filename:     'order.pdf',
      image:        { type: 'jpeg', quality: 0.98 },
      html2canvas:  { scale: 2, width: 1500, },
      jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
    };

    e.preventDefault();

    // Hide on print:
    buttonOne.classList.add("orderForm__hidden");
    buttonGDPR.classList.add("orderForm__hidden");
    buttonTwo.classList.add("orderForm__hidden");
    sumsContainer.classList.add("orderForm__hidden");
    customerAddress.classList.add("orderForm__hidden");
    customerSSN.classList.add("orderForm__hidden");
    customerPostalCode.classList.add("orderForm__hidden");
    customerEmail.classList.add("orderForm__hidden");
    customerEmployer.classList.add("orderForm__hidden");
    customerPhoneNumber.classList.add("orderForm__hidden");
    for (let node of deletedArticles) {
      node.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.classList.add("orderForm__hidden");
    }


    html2pdf().from(order).set(opt).save().then(()=>{
      buttonOne.classList.remove("orderForm__hidden");
      buttonGDPR.classList.remove("orderForm__hidden");
      buttonTwo.classList.remove("orderForm__hidden");
      sumsContainer.classList.remove("orderForm__hidden");
      customerAddress.classList.remove("orderForm__hidden");
      customerSSN.classList.remove("orderForm__hidden");
      customerPostalCode.classList.remove("orderForm__hidden");
      customerEmail.classList.remove("orderForm__hidden");
      customerEmployer.classList.remove("orderForm__hidden");
      customerPhoneNumber.classList.remove("orderForm__hidden");
      for (let node of deletedArticles) {
        node.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.classList.remove("orderForm__hidden");
      }
    });
  }
  
  if (orderInfo !== undefined) {
    return (
      <div className="orderForm">
        {Object.keys(orderInfo).map((order) => (
          <div key={order.Id}>
            <div className="orderForm__Container">
              <div className="orderForm__header">
                { orderInfo && (<button onClick={(e) => print(e)} className="orderForm__button">Skriv ut</button>) }
                { orderInfo && (<button onClick={(e) => printGDPR(e)} className="orderForm__buttonGDPR">GDPR-utskrift</button>) }
                { orderInfo && (<button onClick={(e) => back(e)} className="orderForm__button">Tillbaka</button>) }
                <div className="orderForm__latestChanged"><span>Senaste ändrad: <CalendarTodayIcon className="orderForm__icons" /> {orderInfo[order].OrderHead.LastChange ? orderInfo[order].OrderHead.LastChange.datechanged.slice(0,10) : "-"}<AccessTimeIcon className="orderForm__icons" /> {orderInfo[order].OrderHead.LastChange ? orderInfo[order].OrderHead.LastChange.datechanged.slice(11,16) : "-"}<PersonOutlineIcon className="orderForm__icons" /> {orderInfo[order].OrderHead.LastChange ? orderInfo[order].OrderHead.LastChange.name : "-"}</span></div>
                <div className="orderForm__title">
                  <h1>ORDERUPPGIFTER</h1>
                  <div className="orderForm__buttonWrap">
                    <h3 className="orderForm__orderType">Ordertyp: <span>{orderInfo[order].OrderHead.OrderType}</span></h3>
                    {/*
                    <PrintComponent />
                    <button
                      style={{
                        width: "100px",
                        height: "40px",
                        fontSize: "12px",
                        borderRadius: "5px",
                      }}
                      className={
                        toggleState === 1
                          ? "orderForm__content active-content"
                          : "orderForm__content"
                      }
                    >
                      Spara
                    </button>
                    <button
                      style={{
                        width: "100px",
                        height: "40px",
                        fontSize: "12px",
                        borderRadius: "5px",
                      }}
                      className={
                        toggleState === 2
                          ? "orderForm__content active-content"
                          : "orderForm__content"
                      }
                    >
                      Skicka
                    </button>
                    */}
                  </div>
                </div>
                <div className="orderForm__subHeader">
                  <div className="orderForm__orderContainer">
                    <div className="orderForm__orderNr">
                      <span>Ordernummer</span>
                      <h3>{orderInfo[order].OrderHead.OrderNo}</h3>
                    </div>
                    <div className="orderForm__datum">
                      <span>Orderdatum</span>
                      <h3>{orderInfo[order].OrderHead.OrderDate.split("", 10)}</h3>
                    </div>
                    <div className="orderForm__status">
                      <span>Orderstatus</span>
                      <h3>{orderInfo[order].OrderHead.OrderStatusText}</h3>
                    </div>
                  </div>
                  <div className="orderForm__deliveryContainer">
                    <span>Leveranssätt</span>
                    <h3>{orderInfo[order].OrderHead.Infos.Personalcyklar !== undefined && orderInfo[order].OrderHead.Infos.Personalcyklar.leverans_alternativ !== "" ? orderInfo[order].OrderHead.Infos.Personalcyklar.leverans_alternativ : ""}</h3>
                  </div>
                  <div className="orderForm__companyContainer">
                    <span>Företag</span>
                    <h3>{orderInfo[order].OrderHead.Customer.Company}</h3>
                  </div>
                </div>
                <div className="orderForm__customer">
                  <div className="orderForm__customerCard">
                      <div className="orderForm__customerCardRow">
                        <div className="orderForm__customerCardRowItem"><span>Kundnamn</span></div><div className="orderForm__customerCardRowItem"><h3>{`${orderInfo[order].OrderHead.Customer.FirstName} ${orderInfo[order].OrderHead.Customer.LastName}`}</h3></div><div className="orderForm__customerCardRowItem"><span>Adress</span></div><div className="orderForm__customerCardRowItem"><h3>{orderInfo[order].OrderHead.Customer.Address}</h3></div>
                      </div>
                      <div className="orderForm__customerCardRow">
                        <div className="orderForm__customerCardRowItem"><span>Personnummer</span></div><div className="orderForm__customerCardRowItem"><h3>{orderInfo[order].OrderHead.Customer.SocialSecNoVerifyStatus ? (<span className="orderForm__customerSocialSecNumVerified">{orderInfo[order].OrderHead.Customer.SocialSecNo} <CheckIcon className="orderForm__icons" /> {"VERIFIERAT"}</span>) : (<span className="orderForm__customerSocialSecNumNotVerified">{orderInfo[order].OrderHead.Customer.SocialSecNo} <CloseIcon className="orderForm__icons" /> {"EJ VERIFIERAT"}</span>)}</h3></div><div className="orderForm__customerCardRowItem"><span>Postort</span></div><div className="orderForm__customerCardRowItem"><h3>{`${orderInfo[order].OrderHead.Customer.ZipCode} ${orderInfo[order].OrderHead.Customer.City}`}</h3></div>
                      </div>
                      <div className="orderForm__customerCardRow">
                        <div className="orderForm__customerCardRowItem"><span>E-postadress</span></div><div className="orderForm__customerCardRowItem"><h3>{orderInfo[order].OrderHead.Customer.Email}</h3></div><div className="orderForm__customerCardRowItem"><span>Arbetsgivare</span></div><div className="orderForm__customerCardRowItem"><h3>{orderInfo[order].OrderHead.Customer.Company !== undefined ? orderInfo[order].OrderHead.Customer.Company : "-"}</h3></div>
                      </div>
                      <div className="orderForm__customerCardRow">
                        <div className="orderForm__customerCardRowItem"><span>Telefonnummer</span></div><div className="orderForm__customerCardRowItem"><h3>{orderInfo[order].OrderHead.Customer.Phone}</h3></div>
                      </div>
                  </div>
                </div>
                <div className="orderForm__wholeWidthHorizontalLine"></div>
                <div className="orderForm__shop">
                  <h4>Butik som ordern skickats till</h4>

                  {orderInfo[order].OrderHead.OriginalShop && (
                      <div className="orderForm__assignedShop">
                        <h6>Ursprungsbutik</h6>
                        <h5>{orderInfo[order].OrderHead.OriginalShop.Name}</h5>
                      </div>
                  )}
                  {orderInfo[order].OrderHead.Shop.Name && (
                      <div className="orderForm__assignedShop">
                        <h6>Tilldelad butik</h6>
                        <h5>{orderInfo[order].OrderHead.Shop.Name}</h5>
                      </div>
                  )}
                </div>
                <div className="orderForm__wholeWidthHorizontalLine"></div>
                <div className="orderForm__customerComments">
                  <h5>Kundkommentarer ({orderInfo[order].CustomerComments.length}) <ChatBubbleIcon className="orderForm__commentIcon" /></h5>{expandedCustomerComments ? (<button className="orderForm__expandButton" onClick={() => setExpandedCustomerComments(false)}><ExpandLessIcon /></button>) : (<button className="orderForm__expandButton" onClick={() => setExpandedCustomerComments(true)}><ExpandMoreIcon /></button>)}
                </div>
                <div className="orderForm__comments">
                  { expandedCustomerComments && orderInfo[order].CustomerComments.map((comment, index) => (
                      <div key={index} className="orderForm__customerComment">
                      <Comment comment={comment} />
                      </div>
                  ))}
                </div>
                <div className="orderForm__sportsonComments">
                  <h5>Interna kommentarer ({orderInfo[order].SportsonComments.length}) <ChatBubbleIcon className="orderForm__commentIcon" /></h5>{expandedInternalComments ? (<button className="orderForm__expandButton" onClick={() => setExpandedInternalComments(false)}><ExpandLessIcon /></button>) : (<button className="orderForm__expandButton" onClick={() => setExpandedInternalComments(true)}><ExpandMoreIcon /></button>)}
                </div>
                <div className="orderForm__comments">
                  { expandedInternalComments && orderInfo[order].SportsonComments.map((comment, index) => (
                      <div key={index} className="orderForm__sportsonComment">
                        <Comment comment={comment} />
                      </div>
                  ))}
                </div>
                <hr className="orderForm__horizontalLine" />
                <div className="orderForm__InfoContainer">
                  <h1>ORDERSUMMERING</h1>
                  {/*
                  <div className="orderForm__vy">
                    <span>Välj vy</span>

                    <div className="orderForm__toggleBtn">
                      <button className="product__customerBtn">Kund</button>
                      <button className="product__storeBtn">Butik</button>

                      <div
                        style={{
                          borderBottomLeftRadius: "5px",
                          borderTopLeftRadius: "5px",
                          padding: "15px",
                        }}
                        className={
                          toggleState === 1
                            ? "orderForm__tabs "
                            : "orderForm__tabs active-tabs"
                        }
                        onClick={() => toggleTab(1)}
                      >
                        Kund
                      </div>
                      <div
                        style={{
                          borderBottomRightRadius: "5px",
                          borderTopRightRadius: "5px",
                          padding: "15px",
                        }}
                        className={
                          toggleState === 2
                            ? "orderForm__tabs "
                            : "orderForm__tabs active-tabs"
                        }
                        onClick={() => toggleTab(2)}
                      >
                        Butik
                      </div>
                    </div>

                  </div>
                   */}
                </div>
                <div className="orderForm__sumsContainer">
                  <div className="orderForm__sumsContainerDiv">
                    <h4>Total ordersumma</h4>
                    <h2>{ orderInfo[order].OrderHead.TotalGrossAmount.toLocaleString() } Kr</h2>
                  </div>
                  <div className="orderForm__sumsContainerDiv">
                    <h4>Fakturabelopp { orderInfo[order].OrderHead.InvoiceDiscountText }</h4>
                    <h2>{ orderInfo[order].OrderHead.TotalInvoiceAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") } Kr</h2>
                  </div>
                </div>
                <div
                  className={
                    toggleState === 1
                      ? "orderForm__content active-content"
                      : "orderForm__content"
                  }
                >
                  <div className="orderForm__titelsKund">
                    <h5 className="orderForm__picProductKund">Bild</h5>
                    <h5 className="orderForm__productNameKund">Namn</h5>
                    <h5 className="orderForm__artNrTextKund">Artikelnummer</h5>
                    <h5 className="orderForm__barcodeKund">Streckkod</h5>
                    <h5 className="orderForm__grossPriceKund">Bruttopris</h5>
                    <h5 className="orderForm__amountKund">Antal</h5>
                    <h5 className="orderForm__idKund">Ärendenr/Ramnr</h5>
                  </div>

                  {Object.keys(orderInfo[0].Items).map((index) => (
                    <div className="orderForm__rowContainer">
                      <div className="orderForm__horizontalLine"></div>
                      <div key={index.Id}>
                        <ProductRow
                          orderInfo={orderInfo[0].Items}
                          index={index}
                        />
                      </div>
                    </div>
                  ))}
                </div>

                <div
                  className={
                    toggleState === 2
                      ? "orderForm__content active-content"
                      : "orderForm__content"
                  }
                >
                  <div className="orderForm__titelsButik">
                    <h5 className="orderForm__productTextButik">Produkt</h5>
                    <h5 className="orderForm__artNrTextButik">Artikelnummer</h5>
                    <h5 className="orderForm__priceTextButik">
                      Ursprungligt pris <br /> (kund)
                    </h5>
                    <h5 className="orderForm__shopPriceTextButik">
                      Återköpspris <br />
                      (butik)
                    </h5>
                    <h5 className="orderForm__newPriceTextButik">
                      Begagnad <br />
                      utpris
                    </h5>
                    <h5 className="orderForm__tgTextButik">TG %</h5>
                  </div>

                  {Object.keys(orderInfo[0].Items).map((index) => (
                    <div className="orderForm__rowContainer">
                      <div key={index.Id}>
                        <ProductRowShopView
                          orderInfo={orderInfo[0].Items}
                          index={index}
                        />
                      </div>
                    </div>
                  ))}
                  <button className="orderForm__sendBtn">Skicka</button>
                </div>
                <div className="orderForm__invoiceContainer">
                  <h4>Fakturanummer från butik</h4>
                  {orderInfo[order].OrderHead.Invoices.map((invoice, index, array) => (
                      <>
                        {index !== 0 ? <div className="orderForm__wholeWidthHorizontalLine"></div> : null}
                        <h5><span className="orderForm__invoiceNumber">Faktnr {index+1}</span>{invoice}</h5>
                      </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  } else {
    return <></>;
  }
}

export default OrderForm;
