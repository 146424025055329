import React from "react";
import "./style.css";


function Comment({ comment }) {
    return (
        <>
            <h5>Kommentar från {comment.name}</h5>
            <h6 className="comment__comment_text" dangerouslySetInnerHTML={{__html: comment.comment_text}}></h6>
            <h6>{comment.date_created.slice(0,10)}</h6>
        </>
    );
}

export default Comment;