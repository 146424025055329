import React, { useState, useEffect } from "react";
import "./Kampanj.css";
import { Button } from "@material-ui/core";
import { KampanjData } from "./../components/KampanjData";
import Header from "../components/Header";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Kampanj() {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  });

  const settingsMain = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    asNavFor: ".slider-nav",
    infinite: true,
    centerMode: true,
    className: "center",
  };

  const settingsThumbs = {
    slidesToShow: 12,
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    swipeToSlide: true,
    focusOnSelect: true,
  };
  return (
    <div className="kampanj">
      <div className="test">
        <Header title="Kampanjer" />
      </div>

      <div className="kampanj__title">
        <h1>Kampanjkalender</h1>
      </div>
      <div className="thumbnail-slider-wrap">
        <Slider
          {...settingsThumbs}
          asNavFor={nav1}
          ref={(slider) => setSlider2(slider)}
        >
          {KampanjData.map((slide) => (
            <div className="slick-thumbnail-container">
              <div className="slick-slide" key={slide.id}>
                <Button>{slide.title}</Button>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="slider-wrapper">
        <Slider
          className="slider-container"
          {...settingsMain}
          asNavFor={nav2}
          ref={(slider) => setSlider1(slider)}
        >
          {KampanjData.map((slide) => (
            <div className="slick-container ">
              <div className="slick-slide" key={slide.id}>
                <div className="monthTitle">
                  <h2 className="slick-slide-title">{slide.month}</h2>
                </div>
                <div className="slide-info">
                  <img
                    className="slick-slide-image"
                    src={`https://picsum.photos/800/400?img=${slide.id}`}
                    alt="Slide"
                  />
                  <div className="slide-textInfo">
                    <label className="slick-slide-label">{slide.label}</label>
                    <p className="slider-validDate">29-30</p>
                  </div>
                  <div>
                    <Button className="slider-tag">{slide.tag}</Button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Kampanj;
