import API from "../../utils/API";

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: "LOGIN_REQUEST",
    });

    // const returnData = await API.postLogin({ email, password })
    await API.postLogin({ email, password })

      .then((response) => {
        if (!Object.keys(response.data).length) {
          return;
        }
        return response.data;
      })
      .then((data) => {
        dispatch({
          type: "LOGIN_SUCCESS",
          payload: data,
        });
        localStorage.setItem("token", JSON.stringify(data.token));
      });
  } catch (error) {
    dispatch({
      type: "LOGIN_FAIL",
      payload: error,
    });
  }
};

export const logout = () => async (dispatch) => {
  try {
    API.postLogout();
  } catch(e) {
    console.log("Error during logout");
    console.log(e)
  }
  localStorage.clear()

  dispatch({ type: "USER_LOGOUT" });
};

export const handleResponse = (response) => {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        window.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
};
