export const userReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    //LOGIN
    case "LOGIN_REQUEST":
      return { loading: true };
    case "LOGIN_SUCCESS":
      return {
        loading: false,
        userInfo: payload,
      };
    case "LOGIN_FAIL":
      return {
        ...state,
        loading: false,
        userInfo: null,
        error: payload,
        errorMessage: "Fel email eller lösenord",
      };
    case "USER_LOGOUT":
      return {};
    default:
      return state;
  }
};
