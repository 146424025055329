import React from "react";
import "./style.css";
//import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";

function KontaktCard({ image, title, antal, icon, amount, role, company }) {
  return (
    <div className="kontaktCard">
      {/* <div className="kontaktCard__imgContainer"> */}
      <img src={image} alt="" />
      {/* </div> */}
      <div className="kontaktCard__info">
        <p className="kontaktCard__text">{title}</p>

        <div className="kontaktCard__antalContainer">
          <p className="kontaktCard__antalKontakter">{antal}</p>
          {/* <p className="kontaktCard__peopleIcon">{icon}</p> */}
          <p className="kontaktCard__peopleAmount">{amount}</p>
        </div>
      </div>
    </div>
  );
}

export default KontaktCard;
