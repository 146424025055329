import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Login from "./pages/Login";
import ArticleForm from "./pages/ArticleForm";
import UsedBikesForm from "./pages/UsedBikesForm";
import Giftcard from "./pages/Giftcard";
import Order from "./pages/SearchOrder";
import Ordercentral from "./pages/Ordercentral";
import Orderhantering from "./pages/Orderhantering";
import Formanscykel from "./pages/Formanscykel";
import News from "./pages/News";
import Kampanj from "./pages/Kampanj";
import ButiksService from "./pages/ButiksService";
import Leverantor from "./pages/Leverantor";
import Kontakt from "./pages/Kontakt";
import API from "./utils/API";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "./Redux/actions/userActions";

function App() {
  const { userInfo } = useSelector((state) => state.user);
  const [inactive, setInactive] = useState(false);
    const dispatch = useDispatch();

  const validateToken = async () => {
      return await API.validateToken().then((response) => {
          if (response.status === 200) {
              return true;
          } else {
              return false;
          }
      });
  }
  // Check for token every minute otherwise logout:
  useEffect(() => {
            setInterval(() => {
                 validateToken().then((response) => {
                     if(!response)
                        dispatch(logout());
                 });
            }, 60000);
        }, []);
  return (
    <div>
      <Router>
        <div className="app">
          {!userInfo && !localStorage.getItem("token") ? (
              <>
                  <Redirect to="/" />
                  <Login />
              </>
          ) : validateToken() && (
            <div>
              <div className="app__nav">
                <Sidebar
                  onCollapse={(inactive) => {
                    setInactive(inactive);
                  }}
                />
              </div>

              <div className={`app__container ${inactive ? "inactive" : ""}`}>
                <Switch>
                  <Route path="/" exact component={News}></Route>
                  <Route path="/giftcard" exact component={Giftcard}></Route>
                  <Route path="/order" exact component={Order}></Route>
                  <Route path="/ordercentral" exact component={Ordercentral}></Route>
                  <Route path="/articleform" exact component={ArticleForm}></Route>
                  <Route path="/kampanj" exact component={Kampanj}></Route>
                  <Route path="/butiksservice" exact component={ButiksService}></Route>
                  <Route path="/leverantor" exact component={Leverantor}></Route>
                  <Route path="/kontakt" exact component={Kontakt}></Route>
                  <Route path="/orderhantering" exact component={Orderhantering}></Route>
                  <Route path="/formanscykel" exact component={Formanscykel}></Route>
                    <Route path="/usedbikesform" exact component={UsedBikesForm}></Route>
                </Switch>
              </div>
            </div>
          )}
        </div>
      </Router>
    </div>
  );
}

export default App;
