import React, { useEffect, useState } from "react";
import axios from "axios";
import BootStrapTable from "react-bootstrap-table-next";
//import paginationFactory from "react-bootstrap-table2-paginator";
import { Modal, Button } from "react-bootstrap";

import "./style.css";

function Pagination() {
  const [players, setPlayers] = useState([]);
  const [modalInfo, setModalInfo] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getPlayerData = async () => {
    try {
      const data = await axios.get(
        "https://nba-players.herokuapp.com/players-stats"
      );
      setPlayers(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getPlayerData();
  }, []);

  // Put in the columns you need from the table!!!

  const columns = [
    { dataField: "Typ", text: "Typ" },
    { dataField: "name", text: "Namn" },
    { dataField: "points_per_game", text: "Sensast uppdaterad" },
    { dataField: "team_name", text: "Status" },
  ];

  const rowEvents = {
    onClick: (e, row) => {
      console.log(row);
      setModalInfo(row);
      toggleTrueFalse();
    },
  };

  const toggleTrueFalse = () => {
    setShowModal(handleShow);
  };

  function ModalContent() {
    return (
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{modalInfo.name} </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Modal body text goes here.</p>
          </Modal.Body>

          <Modal.Footer>
            <div className="button__container">
              <Button className="button__open" variant="secondary">
                Öppna
              </Button>
              <Button className="button__download" variant="secondary">
                Ladda ner
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  return (
    <div className="pagination">
      <BootStrapTable
        keyField="name"
        data={players}
        hover
        columns={columns}
        // pagination={paginationFactory()}
        rowEvents={rowEvents}
      />

      {show ? <ModalContent onClick={handleShow} /> : null}
    </div>
  );
}

export default Pagination;
