import React from "react";
//import { Button, IconButton } from "@material-ui/core";
//import "./style.css";
import KontaktPersonCard from "../KontaktPersonCard";
import sportsonIcon from "../../img/sportsonS.png";
//import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";

function KontaktPersonContainer() {
  return (
    <div className="kontaktContainer">
      <div className="kontaktContainer__container">
        <div className="kontaktContainer__row">
          <KontaktPersonCard
            image={sportsonIcon}
            name="Albin Svensson"
            role="mekaniker"
            company="Sportson"
          />
          <KontaktPersonCard
            image={sportsonIcon}
            name="Berit Bergström"
            role="mekaniker"
            company="Sportson"
          />
          <KontaktPersonCard
            image={sportsonIcon}
            name="Daniel Larsson"
            role="mekaniker"
            company="Sportson"
          />
        </div>
      </div>
      <hr className="kontaktContainer__verticalLine" />
    </div>
  );
}

export default KontaktPersonContainer;
