const initialState = {
  loading: false,
  articleInfo: [],
  manufacturerList: [],
  categoryList: [],
  supplierList: [],
};

export const articleReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    // GET ARTICLE
    case "GET_ARTICLE_REQUEST":
      return { loading: true };
    case "GET_ARTICLE_SUCCESS":
      return {
        loading: false,
        articleInfo: payload,
      };
    case "GET_ARTICLE_FAIL":
      return {
        ...state,
        loading: false,
        articleInfo: null,
        error: payload,
      };
    case "POST_ARTICLE":
      return {
        ...state,
        articleInfo: payload,
      };
    case "GET_MANUFACTURER_LIST":
      return { manufacturerList: true };
    case "GET_CATEGORY_LIST":
      return { categoryList: true };
    case "GET_SUPPLIER_LIST":
      return { supplierList: true };
    default:
      return state;
  }
};
