import React from "react";
import Kvittens from "../KvittensUI";

export class ComponentToPrint extends React.PureComponent {
  render() {
    return (
      <div>
        {" "}
        <Kvittens />
      </div>
    );
  }
}
