import React from "react";
import "./style.css";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  //Slide,
} from "@material-ui/core";
//import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

// const theme = createMuiTheme({
//   shape: {
//     borderRadius: 10,
//   },
// });

function InputModal({ title, bodyContent, buttonName, open, handleClose }) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogActions className="modal__topHeader">
          {" "}
          <span onClick={handleClose} className="modal__closeBtn">
            x
          </span>
        </DialogActions>
        <DialogTitle className="modal__header">{title}</DialogTitle>
        <DialogContent className="modal__body ">
          <TextField
            label="Lägg till kommentar"
            variant="outlined"
            name="Comments"
            multiline
            rows={4}
            fullWidth
          >
            {bodyContent}
          </TextField>
        </DialogContent>
        <button onClick={handleClose} className="modal__button">
          {buttonName}{" "}
        </button>
      </Dialog>
    </div>
  );
}

export default InputModal;
