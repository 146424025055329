import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import RadioButtons from "../RadioButtons";
import API from "../../utils/API";
import { useForm } from "react-hook-form";
import Modal from "../Modal-ConfirmAlert";
import "./style.css";

function Heads({ selectedValue, option, supportSubTypeID }) {
  const [subjectType, setSubjectType] = useState("");
  const [nameType, setNameType] = useState("");
  const [numberType, setNumberType] = useState("");
  const [emailType, setEmailType] = useState("");
  const [accessLevelType, setAccessLevelType] = useState("");
  const [computerType, setComputerType] = useState("");
  const [problemType, setProblemType] = useState("");
  const [comment, setComment] = useState("");
  const [checked, isChecked] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const [files, setFiles] = useState([]);

  const closeModalHandler = () => setShowModal(false);

  const handleOnChange = (e) => {
    const fileList = e.target.files;
    let tempFiles = [];
    let index = 0; // Setting index to array index so it is easy to remove an element in the handleRemoveFile function

    for (let file of fileList) {
      tempFiles.push({ index: index, file: file, name: file.name});
      index++;
    }
    for (let file of files) {
      tempFiles.push({ index: index, file: file.file, name: file.name});
      index++;
    }
    setFiles(tempFiles);
  }

  const handleRemoveFile = (e) => {
    let tempList = files;
    if (tempList.length === 1){
      setFiles([]);
    } else {
      tempList.splice(e.target.id, 1);
      for (let i = parseInt(e.target.id); i < tempList.length; i++) {
        tempList[i].index = i;
      }
      setFiles([...tempList]);
    }
  }

  const { /* control, */ handleSubmit, errors, register } = useForm({
    reValidateMode: "onChange",
    defaultValues: {
      subjectType,
      nameType,
      numberType,
      emailType,
      accessLevelType,
      computerType,
      problemType,
      comment,
      files,
      checked,
    },
  });

  const onSubmit = () => {
    const ticketbody = {
      subjectType,
      nameType,
      numberType,
      emailType,
      accessLevelType,
      computerType,
      problemType,
      comment,
      files,
      checked,
    };

    /*
    API.getSupportSubType()
        .then((supportSubTypeID) => {
          console.log("SubtypeID: " + supportSubTypeID);
        })
        .catch((error) => {
          console.log("Supportsubtypen gick inte att hitta");
        });
    console.log("SupporttypID: " + supportTypeID);
     */

    API.postSupportTicket(ticketbody, supportSubTypeID)
      .then((ticketid) => {
        setShowModal(true);
        setTitle(`Ditt supportärende med nummer ${ticketid} har skapats!`);
        handleClear();
      })
      .catch((error) => {
        setShowModal(true);
        setTitle("Något gick fel, din förfrågan har inte skickats");
      });
    /*
    API.postFiles(files)
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
     */
  };

  const handleClear = () => {
    setNameType("");
    setNumberType("");
    setEmailType("");
    setAccessLevelType("");
    setComputerType("");
    setProblemType("");
    setComment("");
    setFiles([]);
    isChecked("");
  };

  const handleRadioBtn = (e) => {
    isChecked(e.target.value);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <select
            className="itSupport__select"
            name="subjectType"
            onChange={(e) => setSubjectType(e.target.value)}
            value={subjectType}
            ref={register({ required: true })}
        >
          <option className="itSupport__optionText" value="">
            Välj aktuellt ärende *
          </option>
          <option>Heads - Support/felanmälan</option>
          <option>Heads/SS - Lägg till användare</option>
        </select>
        {subjectType === "Heads/SS - Lägg till användare" && (
            <>
              <TextField
                  className="headsKassa__input"
                  label="För- och efternamn"
                  variant="outlined"
                  name="nameType"
                  fullWidth
                  onChange={(e) => setNameType(e.target.value)}
                  value={nameType}
                  inputRef={register({ required: true })}
                  error={errors.nameType}
              >
              </TextField>
              {errors.nameType && (
                  <p
                      className="errorMessage"
                  >
                    {" "}
                    Ange namn *
                  </p>
              )}
              <TextField
              className="headsKassa__input"
              label="Mobilnummer"
              variant="outlined"
              name="numberType"
              fullWidth
              onChange={(e) => setNumberType(e.target.value)}
              value={numberType}
              inputRef={register({ required: true })}
              error={errors.numberType}
              >
              </TextField>
              {errors.numberType && (
                  <p
                      className="errorMessage"
                  >
                    {" "}
                    Ange nummer *
                  </p>
              )}
              <TextField
                  className="headsKassa__input"
                  label="Mejladress"
                  variant="outlined"
                  name="emailType"
                  fullWidth
                  onChange={(e) => setEmailType(e.target.value)}
                  value={emailType}
                  inputRef={register({ required: true })}
                  error={errors.emailType}
              >
              </TextField>
              {errors.emailType && (
                  <p
                      className="errorMessage"
                  >
                    {" "}
                    Ange mejladress *
                  </p>
              )}
              <select
                  className="itSupport__select"
                  name="accessLevelType"
                  onChange={(e) => setAccessLevelType(e.target.value)}
                  value={accessLevelType}
                  ref={register({ required: true })}
              >
                <option className="itSupport__optionText" value="">
                  Behörighetsnivå i Heads *
                </option>
                <option>BC</option>
                <option>Inköp</option>
                <option>Kassa</option>
              </select>
            </>
        )}
        {subjectType === "Heads - Support/felanmälan" && (
        <TextField
          className="headsKassa__input"
          label="Vilken dator gäller det? * (Ange datornamn, ex. FAR0001)"
          variant="outlined"
          name="computerType"
          fullWidth
          onChange={(e) => setComputerType(e.target.value)}
          value={computerType}
          inputRef={register({ required: true })}
          error={errors.computerType}
        >
          {/* {bodyContent}{" "} */}
        </TextField>
        )}
        {errors.systemType && (
          <p
            className="errorMessage"
          >
            {" "}
            Ange typ av fråga *
          </p>
        )}
        {subjectType === "Heads - Support/felanmälan" && (
        <select
          className="itSupport__select"
          name="problemType"
          onChange={(e) => setProblemType(e.target.value)}
          value={problemType}
          ref={register({ required: true })}
        >
          <option className="itSupport__optionText" value="">
            Är det fel, behövs en förändring eller en instruktionsmanual? *
          </option>
          <option>Har hittat en bugg/fel i systemet</option>
          <option>Skulle vilja ändra/lägga till detta</option>
          <option>Hur ska jag göra detta? Lär mig</option>
        </select>
        )}
        {subjectType === "Heads - Support/felanmälan" && (
        <TextField
          className="headsKassa__input"
          label="Beskriv ditt problem så utförligt du kan *"
          variant="outlined"
          name="comments"
          multiline
          rows={4}
          fullWidth
          onChange={(e) => setComment(e.target.value)}
          value={comment}
          inputRef={register({ required: true })}
          error={errors.comments}
        ></TextField>
            )}
        <div className="fileUpload">
          <TextField
              name="fileField"
              label="Ladda upp filer"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              fullWidth
          >
          </TextField>
          <input value="" title="" className="fileInput" type="file" placeholder="" name="file" onChange={handleOnChange} multiple />
          {files.length > 0 ? <ul className="filesUl">
            {files.map((file) => (
                <li>
                  {file.name} <button className="removeFileButton" type="button" onClick={handleRemoveFile} id={file.index}>X</button>
                </li>
            ))}
          </ul>: null}
        </div>
        {subjectType === "Heads - Support/felanmälan" && (
            <RadioButtons checked={handleRadioBtn} />
        )}
        <button className="modal__button" type="submit">
          Skicka
        </button>
        <Modal
          showModal={showModal}
          closeModalHandler={closeModalHandler}
          title={title}
          buttonName="Stäng"
        />
      </form>
    </div>
  );
}

export default Heads;
