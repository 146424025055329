import React, { useState } from "react";
import "./style.css";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

function Calendar() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  return (
    <div className="calendar">
      <DatePicker
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        inline
      />
      <div className="calendar__btnContainer">
        <button className="calendar__closeBtn">Avbryt</button>
        <button className="calendar__doneBtn">Klar</button>
      </div>
    </div>
  );
}

export default Calendar;
