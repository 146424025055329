import React, { useState } from "react";
import "./Formanscykel.css";
// import Header from "../components/Header";
import CalendarTimeline from "../components/CalendarTimeline";
import { Tabs, Tab, AppBar } from "@material-ui/core";
// import MainHeader from "../components/MainHeader";
import Header from "../components/Header";
import Switch from "../components/Switch";
import Calendar from "../components/Calendar";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import ListIcon from "@material-ui/icons/List";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
// import { CursorMarker } from "react-calendar-timeline";

function TabPanel(props) {
  const { children, value, index } = props;

  return <div>{value === index && <p>{children}</p>}</div>;
}

function Formanscykel() {
  const [value, setValue] = useState(0);
  const [toggleState, setToggleState] = useState(1);
  const [toggled, setToggled] = useState(1);
  const [showCalendar, setShowCalendar] = useState(false);
  // const [formanscykel, setFormanscykel] = useState(false);

  // const showOpen = () => setFormanscykel(!formanscykel);

  const handleTabs = (event, newValue) => {
    setValue(newValue);
  };

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const toggleTab2 = (index) => {
    setToggled(index);
  };

  return (
    <div className="formanscykel">
      {/* <div className={formanscykel ? "formanscykel" : ""}>
    <button
        className="forman__menuBtn"
        onClick={showOpen}
        style={{ backgroundColor: "green", width: "400px" }}
      >
        Press
      </button> */}
      <div className="formanscykel__headerTitle">
        <Header title="Förmånscykel" />
      </div>

      {/* <div>
        <MainHeader style={{ paddingTop: "100px", backgroundColor: "green" }} />
      </div> */}
      <div className="formanscykel__header">
        {/* <h1 className="formanscykel__title">Förmånscykel</h1> */}

        <AppBar className="formanscykel__tabNav" position="static">
          <Tabs
            value={value}
            onChange={handleTabs}
            TabIndicatorProps={{
              style: { background: "#ffcc00" },
            }}
          >
            <Tab className="formanscykel__tabOption" label="FÖRMÅNSFÖNSTER" />
            <Tab className="formanscykel__tabOption" label="FÖRETAG" />
            <Tab className="formanscykel__tabOption" label="HANDBÖCKER" />
          </Tabs>
        </AppBar>

        <div className="formanscykel__switchRow">
          <Switch rounded={true} />
          <span>Visa endast fönster för min butik</span>
        </div>
        <div className="formanscykel__row2">
          <div className="formanscykel__row2Col1">
            <div className="formanscykel__searchInput">
              <IconButton>
                <SearchIcon />
              </IconButton>
              <InputBase
                className="formanscykel__inputBase"
                placeholder="Sök företag"
                // inputProps={{ "aria-label": "search google maps" }}
              />
            </div>
            <div>
              {showCalendar && <Calendar />}
              <button
                onClick={() => setShowCalendar(!showCalendar)}
                className="formanscykel__buttonText"
              >
                Datum
              </button>
              <select className="formanscykel__select">
                <option className="formanscykel__optionText" value="">
                  Status
                </option>
                <option>Pågående</option>
                <option>Klart att leverera</option>
                <option>Stopp för förändring</option>
                <option>Fönstret stängt</option>
              </select>
              <select className="formanscykel__select">
                <option className="formanscykel__optionText" value="">
                  Företag
                </option>
                <option>Ramnummer</option>
                <option>Fakturanummer</option>
              </select>
              <button className="formanscykel__checkboxBtn">
                <input type="checkbox" className="formanscykel__checkbox" />
                Visa avslutade
              </button>
            </div>
          </div>
          <div className="formanscykel__tabContainer">
            <div className="formanscykel__toggleBtn">
              {/* <button className="product__customerBtn">Kund</button>
                      <button className="product__storeBtn">Butik</button> */}
              <div
                style={{
                  borderBottomLeftRadius: "5px",
                  borderTopLeftRadius: "5px",
                  borderRight: "none",
                  width: "40px",
                  fontSize: "12px",
                  borderColor: "lightgrey",
                }}
                className={
                  toggleState === 1
                    ? "formanscykel__tabs active-tabs"
                    : "formanscykel__tabs"
                }
                onClick={() => toggleTab(1)}
              >
                Månad
              </div>
              <div
                style={{
                  borderBottomRightRadius: "5px",
                  borderTopRightRadius: "5px",
                  width: "25px",
                  fontSize: "12px",
                  borderColor: "lightgrey",
                }}
                className={
                  toggleState === 2
                    ? "formanscykel__tabs active-tabs"
                    : "formanscykel__tabs"
                }
                onClick={() => toggleTab(2)}
              >
                År
              </div>
            </div>
            <div className="formanscykel__toggleBtn2">
              {/* <button className="product__customerBtn">Kund</button>
                      <button className="product__storeBtn">Butik</button> */}
              <div
                style={{
                  borderBottomLeftRadius: "5px",
                  borderTopLeftRadius: "5px",
                  borderRight: "none",
                  width: "25px",
                  fontSize: "12px",
                  borderColor: "lightgrey",
                }}
                className={
                  toggled === 1
                    ? "formanscykel__tabs active-tabs"
                    : "formanscykel__tabs"
                }
                onClick={() => toggleTab2(1)}
              >
                <FormatAlignLeftIcon />
              </div>
              <div
                style={{
                  borderBottomRightRadius: "5px",
                  borderTopRightRadius: "5px",
                  width: "25px",
                  fontSize: "12px",
                  borderColor: "lightgrey",
                }}
                className={
                  toggled === 2
                    ? "formanscykel__tabs active-tabs"
                    : "formanscykel__tabs"
                }
                onClick={() => toggleTab2(2)}
              >
                <ListIcon />
              </div>
            </div>
          </div>

          {/* <button className="formanscykel__Icon">
            <FormatAlignLeftIcon />
          </button>
          <button className="formanscykel__listIcon">
            <ListIcon />
          </button> */}
        </div>
      </div>
      <TabPanel value={value} index={0}>
        <div className="formanscykel__container">
          <CalendarTimeline />
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <h1>Företag</h1>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <h1>Handböcker</h1>
      </TabPanel>
    </div>
  );
}

export default Formanscykel;
