import React, { useState } from "react";
import "./style.css";

function SidebarRow({ active, selected, Icon, title, subMenus, ...props }) {
  const [expand, setExpand] = useState(false);

  return (
    <div
      onClick={props.onClick}
      // className={`sidebarRow ${selected && "selected"}`}
      className={`sidebarRow ${active && "sidebarRow--selected"}`}
    >
      <div onClick={() => setExpand(!expand)} className="sidebar__item">
        <Icon className="sidebar__menuIcon" />
        <span className="sidebarRow__title ">{title}</span>
      </div>
      {subMenus && subMenus.length > 0 ? (
        <ul className={`sidebar__subMenu ${expand ? "active" : ""}`}>
          {subMenus.map((menu, index) => (
            <li key={index}>
              <span>{menu.name}</span>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
}

export default SidebarRow;
