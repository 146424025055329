const initialState = {
  loading: false,
  cardData: [],
};

export const giftCardReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    //CARD
    case "GET_CARDNO_REQUEST":
      return { loading: true };
    case "GET_CARDNO_SUCCESS":
      return {
        loading: false,
        cardNo: payload,
      };
    case "GET_CARDNO_FAIL":
      return {
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

// export const updateGiftCardReducer = (state = {}, action) => {
//   const { type, payload } = action;

//   switch (type) {
//     case "UPDATE_GIFTCARD_REQUEST":
//       return { loading: true };
//     case "UPDATE_GIFTCARD_SUCCESS":
//       return { giftCard: payload };
//     case "UPDATE_GIFTCARD_FROM_BUTTON":
//       return {
//         ...state,
//         isused: true,
//       };
//     case "UPDATE_GIFTCARD_FAIL":
//       return { error: payload };

//     default:
//       return state;
//   }
// };
