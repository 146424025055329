import React, { useState } from "react";
import "./style.css";

import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import { Checkbox } from "@material-ui/core";
import InputModal from "../InputModal";
import { useSelector } from "react-redux";

function ProductRowShopView({ index }) {
  const [toggle, setToggle] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const [receiveCheck, setReceiveChecked] = useState(false);
  const [missCheck, setMissChecked] = useState(false);
  const { orderInfo } = useSelector((state) => state.order);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    // setShowModal(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
    // setShowModal(true);
  };

  const closeModalHandler = () => setShowModal(false);

  const handleToggle = (e) => {
    setToggle((toggle) => !toggle);
    console.log(e.target.value);
  };

  return (
    <div className="productRowShopView">
      <div className="productRowShopView__Container">
        <div className="productRowShopView__container">
          <div className="productRowShopView__infoContainer">
            <img
              className="productRowShopView__img"
              src={orderInfo[0].Items[index].ImageLink}
              alt="Product"
            />
            <div className="productRowShopView__bikeInfo">
              <div className="productRowShopView__supplierTag">
                <h3>{orderInfo[0].Items[index].Supplier[0] !== undefined ? orderInfo[0].Items[index].Supplier[0].Name : null}</h3>
                {/* <span>TAG</span> */}
              </div>
              <h1>{orderInfo[0].Items[index].ProductName}</h1>
              <span>{orderInfo[0].Items[index].FrameNo}</span>
            </div>
          </div>
          <div className="productRowShopView__secondContainer">
            <h3 className="productRowShopView__artNr">
              {orderInfo[0].Items[index].PartNo}
            </h3>
            <h3 className="productRowShopView__price">
              {orderInfo[0].Items[index].LineAmount * 1.25} kr
            </h3>
            <h3 className="productRowShopView__shopPrice">
              {orderInfo[0].Items[index].LineAmount} kr
            </h3>
            <input
              className="productRowShopView__input"
              placeholder="Ange ny pris"
            ></input>
            <h3 className="productRowShopView__tg">50 %</h3>
          </div>
          <div className="productRowShopView__momsContainer">
            <span className="productRowShopView__inklText">Inkl. moms</span>
            <span className="productRowShopView__exklText">Exkl. moms</span>
          </div>
          <div className="productRowShopView__comment">
            <button
              className="productRowShopView__commentBtn"
              onClick={() => {
                handleClickOpen();
                setTitle("Lägg till kommentar");
              }}
            >
              {" "}
              <ChatBubbleOutlineIcon className="productRowShopView__commentIcon" />{" "}
              Kommentera skick
            </button>
            <InputModal
              showModal={showModal}
              closeModalHandler={closeModalHandler}
              title={title}
              bodyContent=""
              buttonName="Spara"
            />
          </div>
          <div className="productRowShopView__thirdContainer">
            {toggle ? (
              <div className="productRowShopView__checkbox">
                Sitter på cykeln
                <Checkbox
                  value={receiveCheck}
                  color="default"
                  onChange={() => setReceiveChecked(!receiveCheck)}
                />{" "}
                {console.log(receiveCheck)}
                Kassera <Checkbox value={missCheck} color="default" />
              </div>
            ) : null}
            <div className="productRowShopView__optionBtnContainer">
              <button
                onClick={handleToggle}
                // value={orderData[o].Id}
                // onClick={(e) => {
                //   if (e.target.value === orderData[o].Id) {
                //     handleToggle();
                //   }
                // }}
                className="productRowShopView__receiveBtn"
              >
                Mottagen
              </button>
              <button
                className="productRowShopView__missingBtn"
                onClick={() => {
                  handleClickOpen();
                  setTitle("Varför saknas artikeln?");
                }}
              >
                Saknas
              </button>
              <InputModal
                open={open}
                handleClose={handleClose}
                title={title}
                bodyContent=""
                buttonName="Spara"
              />
              {/* <Modal
                showModal={showModal}
                closeModalHandler={closeModalHandler}
                title={title}
                bodyContent=""
                buttonName="Spara"
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductRowShopView;
