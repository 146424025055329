import React, { useState } from "react";
import "./Giftcard.css";
import Header from "../components/Header";
import Card from "../components/Card";
import { TextField, Typography } from "@material-ui/core";
import { fetchCard } from "../Redux/actions/giftCardAction";
import { logout } from "../Redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../components/Modal-ConfirmAlert";
import API from "../utils/API";

function Giftcard() {
  const [giftcardNo, setGiftcardNo] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const cardData = useSelector((state) => state.card);
  const dispatch = useDispatch();

  const closeModalHandler = () => setShowModal(false);

  function fetchData(e) {
    e.preventDefault();

    if (giftcardNo === "" || null) {
      setShowModal(true);
      setTitle("Ange kontrollnummer");
    } else if (isNaN(giftcardNo)) {
      setShowModal(true);
      setTitle("Ett kontrollnummer kan bara innehålla siffror");
    } else {
      API.getGiftCard(giftcardNo).then((response) => {
        if(response.status === 200){
          dispatch(fetchCard(encodeURI(giftcardNo)));
          if (!response.data.existingshop) {
            setShowModal(true);
            setTitle("Butiken är inte längre tillgänglig. Fakturera Bike Action");
          }
        } else if (response.status === 401) {
          dispatch(logout());
        } else if (response.status === 404) {
          setShowModal(true);
          setTitle("Presentkortet finns ej");
        }
      });
    }
  }

  return (
    <div className="giftcard">
      <Header title="Presentkort kontroll" />
      <form>
        <div className="giftcard__inputContainer">
          <TextField
            className="giftcard__inputText"
            label="Ange kontrollid"
            variant="outlined"
            type="text"
            name="inputText"
            value={encodeURI(giftcardNo)}
            onChange={(e) => setGiftcardNo(e.target.value)}
          />
          <button
            className="giftcard__searchBtn"
            type="submit"
            onClick={(e) => fetchData(e)}
          >
            SÖK
          </button>
          <Typography className="giftcard__captionText" variant="string">Detta gäller endast Nutid presentkort. Retain 24/Heads hanteras direkt i kassan.</Typography>
          <Modal
            showModal={showModal}
            closeModalHandler={closeModalHandler}
            title={title}
            buttonName="Stäng"
          />
        </div>
      </form>
      <Card cardData={cardData} />
    </div>
  );
}

export default Giftcard;
