import React from "react";
//import { Button, IconButton } from "@material-ui/core";
import "./style.css";
import KontaktCard from "../KontaktCard";
import sportsonIcon from "../../img/sportsonS.png";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";

function KontaktContainer() {
  return (
    <div className="kontaktContainer">
      <div className="kontaktContainer__container">
        <div className="kontaktContainer__row">
          <KontaktCard
            image={sportsonIcon}
            title="ABUS"
            antal="Antal kontakter"
            icon={<PeopleOutlineIcon />}
            amount="8"
          />
          <KontaktCard
            image={sportsonIcon}
            title="Alpina"
            antal="Antal kontakter"
            icon={<PeopleOutlineIcon />}
            amount="8"
          />
          <KontaktCard
            image={sportsonIcon}
            title="Axa"
            antal="Antal kontakter"
            icon={<PeopleOutlineIcon />}
            amount="8"
          />
        </div>
      </div>
      <hr className="kontaktContainer__verticalLine" />
    </div>
  );
}

export default KontaktContainer;
