import React from "react";
import {IconButton } from "@material-ui/core";
import "./style.css";

function AlphabetSearchLayout() {
  return (
    <div className="alphabetSearchLayout">
      <div className="alphabetSearch__buttonContainer">
        <IconButton fullWidth={false}>A</IconButton>
        <IconButton>B</IconButton>
        <IconButton>C</IconButton>
        <IconButton>D</IconButton>
        <IconButton>E</IconButton>
        <IconButton>F</IconButton>
        <IconButton>G</IconButton>
        <IconButton>H</IconButton>
        <IconButton>I</IconButton>
        <IconButton>J</IconButton>
        <IconButton>K</IconButton>
        <IconButton>L</IconButton>
        <IconButton>M</IconButton>
        <IconButton>N</IconButton>
        <IconButton>O</IconButton>
        <IconButton>P</IconButton>
        <IconButton>Q</IconButton>
        <IconButton>R</IconButton>
        <IconButton>S</IconButton>
        <IconButton>T</IconButton>
        <IconButton>U</IconButton>
        <IconButton>V</IconButton>
        <IconButton>W</IconButton>
        <IconButton>Y</IconButton>
        <IconButton>X</IconButton>
        <IconButton>Z</IconButton>
        <IconButton>Å</IconButton>
        <IconButton>Ä</IconButton>
        <IconButton>Ö</IconButton>
      </div>
      <hr className="kontaktContainer__verticalLine" />
    </div>
  );
}

export default AlphabetSearchLayout;
