import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import "./style.css";
import { DialogContent, DialogTitle } from "@material-ui/core";
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import BuildIcon from '@material-ui/icons/Build';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import Portal from "../Portal-Form";
import ItButik from "../IT-Butik-Form";
import Heads from "../HeadsKassa-Form";
import Servicesystem from "../Servicesystem-Form";
import API from "../../utils/API";
import { postSupportSubType } from "../../Redux/actions/itSupportAction";
import { useDispatch /*, useSelector */ } from "react-redux";

function ITSupport({ option }) {
  const dispatch = useDispatch();
  const [supportTypes, setSupportTypes] = useState([]);
  const [supportSubTypes, setSupportSubTypes] = useState([]);
  const [supportTypeID, setSupportTypeID] = useState("");
  const [supportSubTypeID, setSupportSubTypeID] = useState("");

  // const { butiksInfo } = useSelector((state) => state.butikFormReducer);

  const [selectedValue, setSelectedValue] = useState("");

  const handleSelect = (e) => {
    setSelectedValue(e.target.childNodes[e.target.selectedIndex].text);
    setSupportSubTypeID(e.target.value);
    dispatch(postSupportSubType(option));
  };

  const handleTabActive = (e) => {
    const clicked = e;
    const tabLinks = document.getElementsByClassName("supportLink");

    setSupportTypeID(e.target.getAttribute("value"));

    for(let tabLink of tabLinks){
      tabLink.classList.remove("supportLinkClicked");
    }

    clicked.target.classList.add("supportLinkClicked");
  };

  const handleOnClickIcon = (index) => {
    const aTags = document.getElementsByClassName("supportLink");
    aTags[index].click();
  };

  useEffect(() => {
    API.getSupportType("").then((res) => {
      setSupportTypes(res.data);
    })
  }, []);

  useEffect(() => {
    API.getSupportSubType(supportTypeID).then((res) => {
      setSupportSubTypes(res.data);
    })
  }, [supportTypeID]);

  // const onSubmit = (e) => {
  //   e.preventDefault();
  // };

  return (
      <Router>
        <div>
          <DialogTitle className="modal__header">Hej! Vad behöver du hjälp med?</DialogTitle>
          <Link to="/support/it"><DesktopWindowsIcon onClick={(e) => handleOnClickIcon(0)} className="tabSupportIcon" /><a value={supportTypes[0] ? supportTypes[0]._id : ""} className="supportLink" onClick={handleTabActive}>{supportTypes[0] ? supportTypes[0].name : ""}</a></Link>
          <Link to="/construction"><QuestionAnswerIcon onClick={(e) => handleOnClickIcon(1)} className="tabSupportIcon" /><a value={supportTypes[1] ? supportTypes[1]._id : ""} className="supportLink" onClick={handleTabActive}>{supportTypes[1] ? supportTypes[1].name : ""}</a></Link>
          <Link to="/construction"><HeadsetMicIcon onClick={(e) => handleOnClickIcon(2)} className="tabSupportIcon" /><a value={supportTypes[2] ? supportTypes[2]._id : ""} className="supportLink" onClick={handleTabActive}>{supportTypes[2] ? supportTypes[2].name : ""}</a></Link>
          <Link to="/construction"><AccessTimeIcon onClick={(e) => handleOnClickIcon(3)} className="tabSupportIcon" /><a value={supportTypes[3] ? supportTypes[3]._id : ""} className="supportLink" onClick={handleTabActive}>{supportTypes[3] ? supportTypes[3].name : ""}</a></Link>
          <Link to="/construction"><BuildIcon onClick={(e) => handleOnClickIcon(4)} className="tabSupportIcon" /><a value={supportTypes[4] ? supportTypes[4]._id : ""} className="supportLink" onClick={handleTabActive}>{supportTypes[4] ? supportTypes[4].name : ""}</a></Link>
          <Link to="/construction"><AttachMoneyIcon onClick={(e) => handleOnClickIcon(5)} className="tabSupportIcon" /><a value={supportTypes[5] ? supportTypes[5]._id : ""} className="supportLink" onClick={handleTabActive}>{supportTypes[5] ? supportTypes[5].name : ""}</a></Link>
          <DialogContent className="modal__body ">
            <Switch>
              <Route path="/support/it">
                <h5 className="itSupport__label">{selectedValue}</h5>
                  <div>
                    <select
                      className="itSupport__select"
                      name="category"
                      onChange={handleSelect}
                      value={selectedValue}
                    >
                      <option className="itSupport__optionText" value={selectedValue === "" ? "" : supportSubTypeID}>
                        {selectedValue === "" ? "Välj kategori" : selectedValue}
                      </option>
                      {supportSubTypes.map((supportSubType) => (
                        <option key={supportSubType._id} value={supportSubType._id} hidden={selectedValue === supportSubType.name ? "Hidden" : ""}>{supportSubType.name}</option>
                      ))}
                    </select>
                    <div>
                      {selectedValue === "Butik-IT" && (
                        <div>
                          <ItButik selectedValue={selectedValue} option={option} supportSubTypeID={supportSubTypeID} />
                        </div>
                      )}
                    </div>
                    {selectedValue === "Portalen" && (
                      <div>
                        <Portal selectedValue={selectedValue} option={option} supportSubTypeID={supportSubTypeID} />
                      </div>
                    )}
                  </div>

                  <div>
                    {selectedValue === "Heads" && (
                      <div>
                        {" "}
                        <Heads selectedValue={selectedValue} option={option} supportSubTypeID={supportSubTypeID} />
                      </div>
                    )}
                  </div>
                  <div>
                    {selectedValue === "Servicesystem" && (
                        <div>
                          {" "}
                          <Servicesystem selectedValue={selectedValue} option={option} supportSubTypeID={supportSubTypeID} />
                        </div>
                    )}
                  </div>
                </Route>
                <Route path="/construction">
                    <h5 className="itSupport__label">Under konstruktion</h5>
                </Route>
            </Switch>
          </DialogContent>
          {/* <button className="modal__button" type="submit">
              Skicka
            </button> */}
        </div>
      </Router>
  );
}

export default ITSupport;
