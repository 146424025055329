import React from "react";
import "./style.css";

function KontaktPersonCard({ image, name, role, company }) {
  return (
    <div className="kontaktPersonCard">
      {/* <div className="kontaktCard__imgContainer"> */}
      <img src={image} alt="" />
      {/* </div> */}
      <div className="kontaktPersonCard__info">
        <p className="kontaktPersonCard__text">{name}</p>
        <div className="kontaktPersonCard__personInfo">
          <p className="kontaktPersonCard__role">{role}</p>
          <p className="kontaktPersonCard__company">{company}</p>
        </div>
      </div>
    </div>
  );
}

export default KontaktPersonCard;
