import React from "react";
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from "react-router-dom";
import "./Ordercentral.css";
import Header from "../components/Header";
//import NewsfeedBanner from "../components/NewsfeedBanner";
//import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import Orderhantering from "./Orderhantering";
import OrderForm from "../components/OrderForm";
import OrderFormReturn from "../components/OrderFormReturn";

function Ordercentral() {

    const handleTabActive = (e) => {
        const clicked = e;
        const tabLinks = document.getElementsByClassName("ordercentral__tabLink");

        for(let tabLink of tabLinks){
            tabLink.classList.remove("ordercentral__tabLinkClicked");
        }

        clicked.target.classList.add("ordercentral__tabLinkClicked");
    };

    const handleTabActiveLarsson = (e) => {
        const clicked = e;
        const tabLinks = document.getElementsByClassName("ordercentral__larsson__tabLink");

        for(let tabLink of tabLinks){
            tabLink.classList.remove("ordercentral__larsson__tabLinkClicked");
        }

        clicked.target.classList.add("ordercentral__larsson__tabLinkClicked");
    };

    return (
        <Router>
            <div className="ordercentralTitle__container">
                <Header title="Ordercentral" />
            </div>
            <div className="ordercentral__container">
                {/*
                <NewsfeedBanner title="AKTUELLA NYHETER" />
                */}
                <div className="ordercentral__tab__container">
                    <Link to="/ordercentral/larsson/order"><a className="ordercentral__tabLink ordercentral__larsson__tabLinkClicked" onClick={handleTabActive}>LARSSON</a></Link>
                    {/*
                    <Link to="/ordercentral/formanscykel"><a className="ordercentral__tabLink" onClick={handleTabActive}>FÖRMÅNSCYKEL | Fönster</a></Link>
                    <Link to="/ordercentral/webbordrar"><a className="ordercentral__tabLink" onClick={handleTabActive}>WEBBORDRAR</a></Link>
                    <Link to="/ordercentral/avtalskunder"><a className="ordercentral__tabLink" onClick={handleTabActive}>AVTALSKUNDER</a></Link>
                    <Link to="/ordercentral/clickochcollect"><a className="ordercentral__tabLink" onClick={handleTabActive}>CLICK & COLLECT</a></Link>
                    */}
                    {/*
                    <button className="ordercentral__supportButton"><HeadsetMicIcon className="ordercentral__supportIcon" />SUPPORT</button>
                    */}
                </div>
            </div>
            <Switch>
                <Route path="/ordercentral/larsson">
                    <Orderhantering />
                    <div className="ordercentral__orderReturnDiv">
                        <Link to="/ordercentral/larsson/order"><a className="ordercentral__larsson__tabLink ordercentral__larsson__tabLinkClicked" onClick={handleTabActiveLarsson}>ORDER</a></Link>
                        {/* <Link to="/ordercentral/larsson/return"><a className="ordercentral__larsson__tabLink" onClick={handleTabActiveLarsson}>RETUR</a></Link> */}
                    </div>
                    <Route path="/ordercentral/larsson/order">
                        <div className="ordercentral__larsson__orderDiv">
                            <OrderForm />
                        </div>
                    </Route>
                    <Route path="/ordercentral/larsson/return">
                        <div className="ordercentral__larsson__orderDiv">
                            <OrderFormReturn />
                        </div>
                    </Route>
                </Route>
                <Route path="/ordercentral/formanscykel">

                </Route>
                <Route path="/ordercentral/webbordrar">

                </Route>
            </Switch>
            <Redirect to="/ordercentral/larsson/order" />
        </Router>
    );
}

export default Ordercentral;
