import API from "../../utils/API";

export const fetchCard = (cardData) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_CARDNO_REQUEST",
    });

    const { data } = await API.getGiftCard(cardData);
    //get sellershopid
    const sellershopid = data.sellershopid;
    // get all the shop
    const shopData = await API.getAllShop(sellershopid);

    // Filter out the correct shop, matching the shops
    /*
    const shop = shopData.data.filter((item) => {
      return item.Code === sellershopid.toString();
    });
     */
    const shop = shopData.data;

    // access the name of the shop we filtered out
    //const shopName = shop[0].Name;
    const shopName = shop.Name;
    // Object from get giftcard, add the shopNAme
    data["shopName"] = shopName;

    dispatch({
      type: "GET_CARDNO_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "GET_CARDNO_FAIL",
      payload: error,
    });
  }
};

// export const updateGiftCard = (giftCard) => async (dispatch) => {
//   try {
//     const { data } = await API.updateGiftCard(giftCard).then((response) => {
//       dispatch({
//         type: "UPDATE_GIFTCARD_REQUEST",
//       });
//     });

//     dispatch({
//       type: "UPDATE_GIFTCARD_SUCCESS",
//       payload: data,
//     });
//   } catch (error) {
//     dispatch({
//       type: "UPDATE_GIFTCARD_FAIL",
//       payload: error,
//     });
//   }
// };

// export const updateGiftcardButton = (giftData) => (dispatch) => {
//   const { data } = API.updateGiftCard(giftData);
//   dispatch({
//     type: "UPDATE_GIFTCARD_FROM_BUTTON",
//     payload: data,
//   });
// };
