import React from "react";
//import { Button, IconButton } from "@material-ui/core";
import "./style.css";
import KontaktCard from "../KontaktCard";
import sportsonIcon from "../../img/sportsonS.png";

function LeverantorCardContainer() {
  return (
    <div className="leverantorCardContainer">
      <div className="leverantorCardContainer__container">
        <div className="leverantorCardContainer__row">
          <KontaktCard image={sportsonIcon} title="ABUS" />
          <KontaktCard image={sportsonIcon} title="Alpina" />
          <KontaktCard image={sportsonIcon} title="Axa" />
        </div>
      </div>
      <hr className="leverantorCardContainer__verticalLine" />
    </div>
  );
}

export default LeverantorCardContainer;
