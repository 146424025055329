import React from "react";

export const KampanjData = [
  {
    id: 1,
    title: "Jan",
    month: "Januari",
    label: "Inbytesgaranti",
    tag: "tillbehör",
  },
  {
    id: 2,
    title: "Feb",
    month: "Februari",
    label: "MTB säsongen är här. Redo?",
    tag: "tillbehör",
  },
  {
    id: 3,
    title: "Mar",
    month: "Mars",
    label: "Barnhjälm MIPS",
    tag: "tillbehör",
  },
  {
    id: 4,
    title: "Apr",
    month: "April",
    label: "50% på MTB hjälm",
    tag: "tillbehör",
  },
  {
    id: 5,
    title: "Maj",
    month: "Maj",
    label: "50% på MTB hjälm",
    tag: "",
  },
  {
    id: 6,
    title: "Jun",
    month: "Juni",
    label: "50% på MTB hjälm",
    tag: "",
  },
  {
    id: 7,
    title: "Jul",
    month: "Juli",
    label: "50% på MTB hjälm",
    tag: "",
  },
  {
    id: 8,
    title: "Aug",
    month: "Augusti",
    label: "50% på MTB hjälm",
    tag: "tillbehör",
  },
  {
    id: 9,
    title: "Sep",
    month: "September",
    label: "50% på MTB hjälm",
    tag: "",
  },
  {
    id: 10,
    title: "Okt",
    month: "Oktober",
    label: "50% på MTB hjälm",
    tag: "",
  },
  {
    id: 11,
    title: "Nov",
    month: "November",
    label: "50% på MTB hjälm",
    tag: "tillbehör",
  },
  {
    id: 12,
    title: "Dec",
    month: "December",
    label: "50% på MTB hjälm",
    tag: "",
  },
];
