import React /*, { useState }*/ from "react";
import "./style.css";
//import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
//import { Checkbox } from "@material-ui/core";
//import InputModal from "../InputModal";
import { useSelector } from "react-redux";

function ProductRow({ index }) {
  //const [toggle, setToggle] = useState(false);
  //const [showModal, setShowModal] = useState(false);
  //const [title, setTitle] = useState("");
  const { orderInfo } = useSelector((state) => state.order);
  //const [isCheck, setIsCheck] = useState("");
  //const [open, setOpen] = React.useState(false);

  /* Comment out unused code for now
  const handleClose = () => {
    setOpen(false);
    // setShowModal(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
    // setShowModal(true);
  };

  const closeModalHandler = () => setShowModal(false);

  const handleChecked = (event) => {
    setIsCheck(event.target.value);
  };

  const handleToggle = () => {
    setToggle((toggle) => !toggle);
  };
   */

  return (
    <div className="productRow">
      <div className="productRow__Container">
        <div className="productRow__container">
          <div className="productRow__infoContainer">
            <img
              className="productRow__img"
              src={orderInfo[0].Items[index].ImageLink}
              alt="Product"
            />
            <div className="productRow__bikeInfo">
              <div className="productRow__supplierTag">
                <h3>
                  {/* {orderInfo[0].Items[index].Manufacturer.ManufacturerCode} */}
                  {orderInfo[0].Items[index].Supplier[0] !== undefined ? orderInfo[0].Items[index].Supplier[0].Name : null}
                </h3>

                {/* <span>TAG</span> */}
              </div>
              <h1>{orderInfo[0].Items[index].ProductName}</h1>
              <div className="productRow__isDiv">
                {orderInfo[0].Items[index].isDeleted ? <h6 className="productRow__itemIsDeleted">RADERAD</h6> : null}
                {orderInfo[0].Items[index].isCustom ? <h6 className="productRow__itemIsCustom">TILLAGD</h6> : null}
                {orderInfo[0].Items[index].isEdited ? <h6 className="productRow__itemIsEdited">ÄNDRAD</h6> : null}
              </div>
              {/*<span>{orderInfo[0].Items[index].FrameNo}</span>*/}
            </div>
          </div>

          <div className="productRow__secondContainer">
            <h3 className="productRow__artNr">
              {orderInfo[0].Items[index].PartNo}
            </h3>
            <h3 className="productRow__barcode">
              {orderInfo[0].Items[index].EanCode}
            </h3>
          </div>
          {/* </div> */}
          {/* ))} */}
          {/* </div>
            ))} */}
          {/*
          <div className="productRow__comment">
            <button
              className="productRow__commentBtn"
              onClick={() => {
                handleClickOpen();
                setTitle("Lägg till kommentar");
              }}
            >
              <ChatBubbleOutlineIcon className="productRow__commentIcon" />{" "}
              Kommentera skick
            </button>
            <InputModal
              open={open}
              closeModalHandler={handleClose}
              title={title}
              bodyContent=""
              buttonName="Spara"
            />
          </div>
          */}
          <div className="productRow__thirdContainer">
            <h3 className="productRow__grossPrice">
              {orderInfo[0].Items[index].GrossAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
            </h3>
          </div>
            {/*
            {console.log(toggle)}
            {toggle ? (
              <div className="productRow__checkbox">
                Sitter på cykeln
                <Checkbox
                  color="default"
                  value="onBike"
                  checked={isCheck === "onBike"}
                  onChange={handleChecked}
                />
                Kassera{" "}
                <Checkbox
                  color="default"
                  value="kassera"
                  checked={isCheck === "kassera"}
                  onChange={handleChecked}
                />
              </div>
            ) : null}
            */}
            {/*
            <div className="productRow__optionBtnContainer">
              <button
                // value={}
                onClick={handleToggle}
                className="productRow__receiveBtn"
              >
                Mottagen
              </button>

              <button
                className="productRow__missingBtn"
                onClick={() => {
                  handleClickOpen();
                  setTitle("Varför saknas artikeln?");
                }}
              >
                Saknas
              </button>
              <InputModal
                open={open}
                handleClose={handleClose}
                title={title}
                // bodyContent=""
                buttonName="Spara"
              />
            </div>
            */}
          <div className="productRow__fourthContainer">
            <h3 className="productRow__quantity">
              {orderInfo[0].Items[index].QtyOrdered}
            </h3>
          </div>
          <div className="productRow__fifthContainer">
            <h6>
              Ärendenummer:
            </h6>
            <h6>
              {orderInfo[0].Items[index].ErrandNo ? orderInfo[0].Items[index].ErrandNo : "-"}
            </h6>
              {orderInfo[0].Items[index].FrameNo ? <h6>Ramnummer:</h6> : null}
            <h6>
              {orderInfo[0].Items[index].FrameNo}
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductRow;
