import axios from "axios";
import authHeader from "../services/auth-header";
axios.defaults.validateStatus = () => true;

const { REACT_APP_DEV_API, REACT_APP_MAIN_API, REACT_APP_LOCAL, REACT_APP_BASE_URL_API } = process.env;

// const baseURL = REACT_APP_LOCAL;
//const baseURL = "http://dev-api.sportson.se";
//const baseURL = "http://localhost:3001";
const baseURL = REACT_APP_BASE_URL_API;

export default {
  getOrder: async function (order) {
    return axios.get(baseURL + `/Orders/GetCollatedOrder/${order}`, {
      headers: authHeader(),
    });
  },

  getLastOrderChange: async function () {
    return axios.get(baseURL + `/Orders/GetLastOrderChange`, {
      headers: authHeader(),
    });
  },

  getCompanyList: async function () {
    return axios.get(baseURL + `/Orders/GetCompanyList`, {
      headers: authHeader(),
    });
  },

  getArticle: async function (product) {
    return axios.get(
      baseURL + `/products/GetProductByPartNoOrBarcode/?code=${product}`,
      {
        headers: authHeader(),
        // Ignore the catch in axios, Continue to check the status
        validateStatus: (status) => {
          if (status < 400 || status === 404) {
            return status;
          }
        },
      }
    );
  },

  getGiftCard: async function (card) {
    // const getparams = JSON.stringify({
    //   search: card,
    // });

    return axios.get(baseURL + `/giftcard/giftcard/?search=${card}`, {
      headers: authHeader(),
    });
  },

  getUserShop: async function () {
    return axios.get(baseURL + `/users/usershop`, {
      headers: authHeader(),
    });
  },

  getUserName: async function () {
    return axios.get(baseURL + `/users/username`, {
      headers: authHeader(),
    });
  },

  getUserId: async function () {
    return axios.get(baseURL + `/users/userid`, {
      headers: authHeader(),
    });
  },

  getSupplier: async function () {
    return axios.get(baseURL + `/products/GetSupplierList/`, {
      headers: authHeader(),
    });
  },

  getManufacturer: async function () {
    return axios.get(baseURL + `/products/GetManufacturerList/`, {
      headers: authHeader(),
    });
  },

  getNewsAsHtml: async function () {
    return axios.get(baseURL + `/rss/GetLatestAsHtml`, {
      headers: authHeader(),
    });
  },

  getCategory: async function () {
    return axios.get(baseURL + `/products/GetCategoryList/`, {
      headers: authHeader(),
    });
  },

  getShop: async function () {
    return axios.get(baseURL + `/users/usershop/`, {
      headers: authHeader(),
    });
  },

  getAllShop: async function (shop) {
    return axios.get(
      baseURL + `/products/GetShopList/?ignoreFilter=true&&gln=${shop}`,
      {
        headers: authHeader(),
      }
    );
  },

  postArticle: async function (data) {
    return axios.post(baseURL + `/products/SubmitProductRequest `, data, {
      headers: authHeader(),
    });
  },

  registerUsedBike: async function (data) {
    return axios.post(baseURL + `/products/RegisterUsedBike`, data, {
      headers: authHeader(),
    });
  },

  postLogin: async function (info) {
    return axios.post(baseURL + `/Authorization/login`, info);
  },

  postLogout: async function () {
    return axios.post(baseURL + `/Authorization/logout`);
  },

  validateToken: async function () {
    return axios.get(baseURL + `/Authorization/validate`, {
      headers: authHeader(),
    });
  },

  /*
  postSupportType: async function (name, ticket) {
    const { Authorization } = await authHeader();

    const header = {
      "Content-Type": "application/json",
      Authorization: Authorization,
    };
    return axios
      .post(
        baseURL + `/Maintenance/SupportType`,
        { name: name },
        {
          headers: header,
        }
      )
      .then((res) => {
        // console.log(res);
        const { name, _id } = res.data;
        this.postSupportSubType(name, _id, ticket);
      });
  },

  postSupportSubType: async function (name, parenttype, ticket) {
    const { Authorization } = await authHeader();
    // console.log(ticket);
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authorization,
    };
    return axios
      .post(
        baseURL + `/Maintenance/SupportSubType`,
        { name, parenttype },
        {
          headers,
        }
      )
      .then((res) => {
        // console.log(res);
        const { _id } = res.data;
        //console.log(_id);
        this.postSupportTicket(ticket, _id);
      });
  },
   */

  getSupportType: async function (_id) {
    return axios.get(
        baseURL + `/Maintenance/SupportType/${_id}`,
        {
          headers: authHeader(),
        }
    );
  },

  getSupportSubType: async function (_id) {
    return axios.get(
        baseURL + `/Maintenance/SupportSubType`,
        {
          headers: authHeader(),
          data: {
            parenttype: _id
          }
        }
    );
  },

  getCollatedOrdersByFilter: async function (data) {
    return new Promise((resolve, reject) => {
      const { Authorization } = authHeader();
      const headers = {
        "Content-Type": "application/json",
        Authorization: Authorization,
      };
      axios
          .post(
              baseURL + `/Orders/GetCollatedOrdersByFilter`,
              {
                byshop: data.byshop,
                bycompany: data.bycompany,
                bytype: data.bytype,
                fromdate: data.fromdate,
                todate: data.todate
              },
              {
                headers,
              }
          )
          .then((res) => {
            resolve(res)
          }).catch((e) => {
        reject(e)
      });
    });
  },

  uploadFiles: async function (formdata) {
    return axios.post(baseURL + `/Files/upload`, formdata, {
      headers: authHeader(),
    });
  },

  postSupportTicket: async function (ticketbody, supportsubtype) {
    return new Promise((resolve, reject) => {
      const { Authorization } = authHeader();

      const headers = {
        "Content-Type": "application/json",
        Authorization: Authorization,
      };
      axios
        .post(
          baseURL + `/Support/supportticket`,
          { ticketbody, supportsubtype },
          {
            headers,
          }
        )
        .then((res) => {
          //console.log(res.data.ticketid);
          resolve(res.data.ticketid)
          // this.postSupportTicket;
        }).catch((e) => {
          reject(e)
      });
    });
  },

  updateGiftCard: async function (data) {
    return axios.put(baseURL + `/Giftcard/giftcard `, data, {
      headers: authHeader(),
    });
  },

  //TestAPI
  // testApi: async function (data) {
  //   return axios.post(baseURL + `products/Test`, data);
  // },
};
