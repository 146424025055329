import React, { useState } from "react";
import "./Kontakt.css";
import Header from "../components/Header";
import Searchbar from "../components/Searchbar";
import AlphabetSearchLayout from "../components/AlphabetSearchLayout.js";
import KontaktContainer from "../components/KontaktContainer";
import KontaktPersonContainer from "../components/KontaktPersonContainer";
import KontaktPersonerPagination from "../components/KontaktPersonerPagination";

import { Tabs, Tab, AppBar } from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index } = props;

  return <div>{value === index && <p>{children}</p>}</div>;
}

function Kontakt() {
  const [value, setValue] = useState(0);
  const handleTabs = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="kontakt">
      <div className="test">
        <Header title="Kontakter" />
      </div>
      <div className="kontakt__title">
        <h1>Kontakter</h1>
      </div>
      <AppBar className="kontakt__headerNav" position="static">
        <Tabs
          value={value}
          onChange={handleTabs}
          TabIndicatorProps={{
            style: { background: "#ffcc00" },
          }}
        >
          <Tab className="kontakt__navOption" label="Företag" />
          <Tab className="kontakt__navOption" label="Personer" />
          <Tab className="kontakt__navOption" label="Sportson" />
        </Tabs>
      </AppBar>
      <Searchbar />
      <TabPanel value={value} index={0}>
        <AlphabetSearchLayout />

        <div>
          <KontaktContainer />
          <KontaktContainer />
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AlphabetSearchLayout />
        <KontaktPersonerPagination />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AlphabetSearchLayout />
        <KontaktPersonContainer />
      </TabPanel>
    </div>
  );
}

export default Kontakt;
