import React from "react";
import "./style.css";

function Select({value, onChange, title, options, optionValues = [] }) {

    return (
        <select onChange={onChange} className="orderhantering__filterSelect">
            <option className="orderhantering__optionText" value="">{title}</option>
            {options && options.map((option,index) => (
                <option key={index} value={optionValues[index]}>{option}</option>
            ))}
        </select>
    );
}

export default Select;