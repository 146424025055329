import React, { useState } from "react";
import "./Leverantor.css";
import Header from "../components/Header";
import AlphabetSearchLayout from "../components/AlphabetSearchLayout.js";
import Searchbar from "../components/Searchbar";
import Pagnation from "../components/Pagination";
import LeverantorCardContainer from "../components/LeverantorCardContainer";

import { Tabs, Tab, AppBar } from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index } = props;

  return <div>{value === index && <p>{children}</p>}</div>;
}

function Leverantor() {
  const [value, setValue] = useState(0);
  const handleTabs = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="leverantor">
      <div className="test">
        <Header title="Leverantörer" />
      </div>
      <div className="leverantor__title">
        <h1>Leverantör</h1>
      </div>
      <AppBar className="leverantor__headerNav" position="static">
        <Tabs
          value={value}
          onChange={handleTabs}
          TabIndicatorProps={{
            style: { background: "#ffcc00" },
          }}
        >
          <Tab className="leverantor__navOption" label="Leverantörer" />
          <Tab className="leverantor__navOption" label="Varumärken" />
        </Tabs>
      </AppBar>
      <Searchbar />
      <TabPanel value={value} index={0}>
        {" "}
        <AlphabetSearchLayout />
        <div>
          <LeverantorCardContainer />
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        {" "}
        <Pagnation />
      </TabPanel>
    </div>
  );
}

export default Leverantor;
