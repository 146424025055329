import React, { useState } from "react";
import "./style.css";
import Calendar from "../Calendar";

import {
  //Button,
  Select,
  FormControl,
  MenuItem,
  //InputLabel,
} from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";

function Searchbar({ date, sort, status }) {
  const [showCalendar, setShowCalendar] = useState(false);
  return (
    <div className="searchbar">
      <div className="searchbar__container">
        <div className="searchbar__input">
          <SearchIcon />
          <input placeholder="Sök..." type="text" />
        </div>
        <FormControl className="news__dateDropdown">
          {/* <Select value="date" disableUnderline>
            <MenuItem value="" disabled>
              {date}
            </MenuItem>
            <MenuItem value="date"> Datum</MenuItem>
          </Select> */}
          <MenuItem value="" disabled>
            {date}
          </MenuItem>
          {showCalendar && <Calendar />}
          <button
            onClick={() => setShowCalendar(!showCalendar)}
            className="formanscykel__buttonText"
          >
            Datum
          </button>
        </FormControl>
        <FormControl className="news__dateDropdown">
          <Select className="searchbar__text" value="date" disableUnderline>
            <MenuItem value="" disabled>
              {sort}
            </MenuItem>
            <MenuItem value="date">Sortera</MenuItem>
          </Select>
        </FormControl>
        {/* <FormControl className="news__dateDropdown">
          <Select value="date" disableUnderline>
            <MenuItem value="" disabled>
              {status}
            </MenuItem>
            <MenuItem value="date">Status</MenuItem>
          </Select>
        </FormControl> */}
      </div>
    </div>
  );
}

export default Searchbar;
