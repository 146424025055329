import React, { useState } from "react";
import "./Login.css";
import sportsonTitel from "./../img/sportsonTitel.png";
import { Button, TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Modal from "../components/Modal-ConfirmAlert";
import API from "../utils/API";

import { login } from "../Redux/actions/userActions";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const { control, register } = useForm();
  const dispatch = useDispatch();

  const closeModalHandler = () => setShowModal(false);

  const handleInput = () => {

    if (email === "" || password === ""){
      setShowModal(true);
      setTitle("Fälten måste vara ifyllda");
    }

    API.postLogin({ email: email, password: password }).then((response) => {
       if (email !== "" && password !== "" && response.status === 401){
         setShowModal(true);
         setTitle("Användarnamn eller lösenord är felaktig");
       } else if (response.status >= 300) {
         setShowModal(true);
         setTitle(`${response.data.message} - ${response.data.details.message}`);
       }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(login(email, password));

    handleInput();
  };

  return (
    <div className="login">
      <div className="login__img">
        <img src={sportsonTitel} alt="" />
      </div>
      <div className="login__container">
        <form onSubmit={handleSubmit}>
          <div className="login__inputContainer">
            <TextField
              id="filled-basic"
              variant="filled"
              as={TextField}
              control={control}
              inputRef={register({ required: true })}
              label="E-post"
              name="Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type="email"
              className="login__textfield"
            ></TextField>
            <TextField
              id="filled-basic"
              variant="filled"
              as={TextField}
              control={control}
              inputRef={register({
                required: true,
              })}
              label="Lösenord"
              name="Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              type="password"
              className="login__textfield"
            ></TextField>
            <Button className="login__button" type="submit">
              LOGGA IN
            </Button>
          </div>
        </form>
      </div>
      <Modal
          showModal={showModal}
          closeModalHandler={closeModalHandler}
          title={title}
          buttonName="Stäng"
      />
    </div>
  );
}

export default Login;
