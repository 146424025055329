import API from "../../utils/API";

export const fetchOrder = (orderInfo) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_ORDER_REQUEST",
    });
    const { data } = await API.getOrder(orderInfo);

    dispatch({
      type: "GET_ORDER_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "GET_ORDER_FAIL",
      payload: error,
    });
  }
};

export const postOrder = (testInfo) => async (dispatch) => {
  try {
    await API.testApi(testInfo).then((response) => {
      dispatch({
        type: "POST_ORDER_SUCCESS",
      });
    });
  } catch (error) {
    dispatch({
      type: "POST_ORDER_FAIL",
      payload: error,
    });
  }
};
