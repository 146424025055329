import React, { useState } from "react";
import "./style.css";
import PrintComponent from "../PrintComponent";
import ProductRow from "../ProductRow-Customer";
import ProductRowShopView from "../ProductRow-Shop";
import { useSelector } from "react-redux";

function OrderFormReturn() {
  const [toggleState, setToggleState] = useState(1);

  const { orderInfo } = useSelector((state) => state.order);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  if (orderInfo !== undefined) {
    return (
      <div className="orderFormReturn">
        {Object.keys(orderInfo).map((order) => (
          <div key={order.Id}>
            <div className="orderFormReturn__Container">
              <div className="orderFormReturn__header">
                <div className="orderFormReturn__title">
                  <h1>
                    {orderInfo[order].OrderHead.Customer.FirstName}{" "}
                    {orderInfo[order].OrderHead.Customer.LastName}
                  </h1>
                  <div className="orderFormReturn__buttonWrap">
                    <PrintComponent />
                    <button
                      style={{
                        width: "100px",
                        height: "40px",
                        fontSize: "12px",
                        borderRadius: "5px",
                      }}
                      className={
                        toggleState === 1
                          ? "orderFormReturn__content active-content"
                          : "orderFormReturn__content"
                      }
                    >
                      Spara
                    </button>
                    <button
                      style={{
                        width: "100px",
                        height: "40px",
                        fontSize: "12px",
                        borderRadius: "5px",
                      }}
                      className={
                        toggleState === 2
                          ? "orderFormReturn__content active-content"
                          : "orderFormReturn__content"
                      }
                    >
                      Skicka
                    </button>
                  </div>
                </div>
                <h3>{orderInfo[order].OrderHead.Customer.Company}</h3>
                <div className="orderFormReturn__subHeader">
                  <div className="orderFormReturn__orderContainer">
                    <div className="orderFormReturn__datum">
                      <span>Orderdatum</span>
                      <h4>
                        {orderInfo[order].OrderHead.OrderDate.split("", 10)}
                      </h4>
                    </div>
                    <div className="orderFormReturn__orderNr">
                      <span>OrderNummer</span>
                      <h4>{orderInfo[order].OrderHead.OrderNo}</h4>
                    </div>
                  </div>
                  <div className="orderFormReturn__priceContainer">
                    <span>Ursprungligt pris (Total inkl. moms)</span>
                    <h3>40.000kr</h3>
                  </div>
                </div>
                <hr className="orderFormReturn__horizontalLine" />
                <div className="orderFormReturn__InfoContainer">
                  <h3>Produkter på ordern</h3>
                  <div className="orderFormReturn__vy">
                    <span>Välj vy</span>

                    <div className="orderFormReturn__toggleBtn">
                      {/* <button className="product__customerBtn">Kund</button>
                      <button className="product__storeBtn">Butik</button> */}
                      <div
                        style={{
                          borderBottomLeftRadius: "5px",
                          borderTopLeftRadius: "5px",
                          padding: "15px",
                        }}
                        className={
                          toggleState === 1
                            ? "orderFormReturn__tabs "
                            : "orderFormReturn__tabs active-tabs"
                        }
                        onClick={() => toggleTab(1)}
                      >
                        Kund
                      </div>
                      <div
                        style={{
                          borderBottomRightRadius: "5px",
                          borderTopRightRadius: "5px",
                          padding: "15px",
                        }}
                        className={
                          toggleState === 2
                            ? "orderFormReturn__tabs "
                            : "orderFormReturn__tabs active-tabs"
                        }
                        onClick={() => toggleTab(2)}
                      >
                        Butik
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    toggleState === 1
                      ? "orderFormReturn__content active-content"
                      : "orderFormReturn__content"
                  }
                >
                  <div className="orderFormReturn__titelsKund">
                    <h5 className="orderFormReturn__productTextKund">Produkt</h5>
                    <h5 className="orderFormReturn__artNrTextKund">Artikelnummer</h5>
                    <h5 className="orderFormReturn__priceTextKund">
                      Ursprungligt pris (kund)
                    </h5>
                  </div>

                  {Object.keys(orderInfo[0].Items).map((index) => (
                    <div className="orderFormReturn__rowContainer">
                      <div key={index.Id}>
                        <ProductRow
                          orderInfo={orderInfo[0].Items}
                          index={index}
                        />
                      </div>
                    </div>
                  ))}
                </div>

                <div
                  className={
                    toggleState === 2
                      ? "orderFormReturn__content active-content"
                      : "orderFormReturn__content"
                  }
                >
                  <div className="orderFormReturn__titelsButik">
                    <h5 className="orderFormReturn__productTextButik">Produkt</h5>
                    <h5 className="orderFormReturn__artNrTextButik">Artikelnummer</h5>
                    <h5 className="orderFormReturn__priceTextButik">
                      Ursprungligt pris <br /> (kund)
                    </h5>
                    <h5 className="orderFormReturn__shopPriceTextButik">
                      Återköpspris <br />
                      (butik)
                    </h5>
                    <h5 className="orderFormReturn__newPriceTextButik">
                      Begagnad <br />
                      utpris
                    </h5>
                    <h5 className="orderFormReturn__tgTextButik">TG %</h5>
                  </div>

                  {Object.keys(orderInfo[0].Items).map((index) => (
                    <div className="orderFormReturn__rowContainer">
                      <div key={index.Id}>
                        <ProductRowShopView
                          orderInfo={orderInfo[0].Items}
                          index={index}
                        />
                      </div>
                    </div>
                  ))}
                  <button className="orderFormReturn__sendBtn">Skicka</button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  } else {
    return <></>;
  }
}

export default OrderFormReturn;
