const initialState = {
  loading: false,
  orderInfo: [],
};

export const orderReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    //ORDER
    case "GET_ORDER_REQUEST":
      return { loading: true };
    case "GET_ORDER_SUCCESS":
      return {
        loading: false,
        orderInfo: payload,
      };
    case "GET_ORDER_FAIL":
      return {
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
