import React, { useState } from "react";
import "./ButiksService.css";
import Header from "../components/Header";
//import Table from "./../components/Table";
import Pagination from "./../components/Pagination";
import Searchbar from "./../components/Searchbar";

import { Tabs, Tab, AppBar } from "@material-ui/core";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

function TabPanel(props) {
  const { children, value, index } = props;

  return <div>{value === index && <p>{children}</p>}</div>;
}

function Butiksservice() {
  const [value, setValue] = useState(0);
  const handleTabs = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="butiksservice">
      <div className="test">
        <Header title="Butiks Service" />
      </div>
      <div className="butiksservice__title">
        <h1>Butiksservice</h1>
      </div>
      <AppBar className="butiksservice__headerNav" position="static">
        <Tabs
          value={value}
          onChange={handleTabs}
          TabIndicatorProps={{
            style: { background: "#ffcc00" },
          }}
        >
          <Tab className="butiksservice__navOption" label="HANDBÖCKER" />
          <Tab className="butiksservice__navOption" label="SÄLJHJÄLP" />
          <Tab className="butiksservice__navOption" label="VERKSTAD" />
          <Tab className="butiksservice__navOption" label="KASSA" />
          <Tab className="butiksservice__navOption" label="KOMMUNIKATION" />
          <Tab className="butiksservice__navOption" label="FRAKT" />
          <Tab className="butiksservice__navOption" label="EKONOMI" />
        </Tabs>
      </AppBar>
      <Searchbar date="Datum" sort="Sortera" />
      <TabPanel value={value} index={0}>
        <Pagination />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Pagination />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Pagination />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Pagination />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Pagination />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Pagination />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Pagination />
      </TabPanel>
    </div>
  );
}

export default Butiksservice;
